import './App.scss';
import React from 'react'
import Amplify from 'aws-amplify'
import awsconfig from './aws-exports'
import Calculator from "./components/BattleField/Calculator";
import Announcement from "./components/Info/Announcement";

Amplify.configure(awsconfig);

function App() {
	return (
			<div className="App">
				{process.env.REACT_APP_LOCAL_DEV==='true' ? <span></span>: <Announcement/>}
				<Calculator/>
			</div>
	);
}

export default App;
