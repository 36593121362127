// Libs
import {observer} from "mobx-react";

const HeroFaceComponent = ({children, hero}) => {
	const style = {}
	let backgroundClass = `no_hero_background-${hero.race}`
	const heroIsPresent = !!hero.heroClass
	if (heroIsPresent) {
		style.backgroundPosition = hero.position
		backgroundClass = `hero_background-${hero.race}`
	}
	return (
			<div className='hero_border'>
				<div className={`hero_background ${backgroundClass}`}>
					<div className={`hero_image${!heroIsPresent ? ' no_hero' : ''}`} style={style}>
						{children}
					</div>
				</div>
			</div>
	)
}

export default observer(HeroFaceComponent);