import Tippy from "@tippyjs/react";
import FortificationInfo from "./FortificationInfo";

const Fortification = ({lvl, count, type, onRemove, dmg}) => {
	const infoProps = {dmg, lvl, type}
	return (
		<Tippy placement="right" arrow={false} theme={'army-unit'} content={<FortificationInfo {...infoProps}/>}>
			<button className={'def_building ' + type} onClick={() => onRemove({type, lvl})}>
				{lvl}
				{(count && count > 1) && (
					<p className="fortifications_count">
						x{count}
					</p>
				)}
			</button>
		</Tippy>
	)
}

export default Fortification;