// Libs
import {createRef, useEffect} from "react";

// data
import art_assets from '../../../data/artifact_assets.json'
import {getSprite} from "../../../utilities";
import Tippy from "@tippyjs/react";
import ArtifactInfo from "./ArtifactInfo";
import {Button} from "react-bootstrap";
import {observer} from "mobx-react";


const ArtifactSlot = ({artifact = {}, clickMe, remove}) => {
	const {isAncient, isUpgraded, position, runes, description, effects, grayed, longName} = artifact
	const {RuneMapping} = art_assets
	const runeRefs = {
		rune0: createRef(),
		rune1: createRef(),
		rune2: createRef(),
		rune3: createRef(),
		rune4: createRef(),
	}

	let artSource = getSprite(position)
	if (Object.keys(artSource).length === 0) artSource.display = 'none'

	const ancientStyle = {
		display: isAncient ? 'block' : 'none'
	}

	const upgradeStyle = {
		display: isUpgraded ? 'block' : 'none'
	}

	useEffect(() => {
		if (runes) {
			runes.split('').sort().forEach((x, i) => {
				const r = RuneMapping[x - 1]
				runeRefs[`rune${i}`].current.className = `rune slot_${i} rune_${r}`
				return 0
			})
		} else {
			[0, 1, 2, 3, 4].forEach(x => {
				runeRefs[`rune${x}`].current.className = `rune slot_${x}`
			})
		}
	})

	return (
		<Tippy placement="right" arrow={false} disabled={!description && !effects} theme={'army-unit'} content={<ArtifactInfo {...artifact}/>}>
			<div className="artifact_wrapper" onClick={(e) => !grayed && clickMe(artifact)}>
				<div className="artifact_background" style={ancientStyle}/>
				<div className={`artifact${grayed ? ' grayed' : ''}`} style={artSource}/>
				<div className="artifact_glow" style={ancientStyle}/>
				<div className="rune slot_0" ref={runeRefs.rune0}/>
				<div className="rune slot_1" ref={runeRefs.rune1}/>
				<div className="rune slot_2" ref={runeRefs.rune2}/>
				<div className="rune slot_3" ref={runeRefs.rune3}/>
				<div className="rune slot_4" ref={runeRefs.rune4}/>
				<div className="upgraded_animated" style={upgradeStyle}/>

				{remove && !grayed && <div className={`remove_artifact${longName? '': ' hide'}`} onClick={e=>e.stopPropagation()}>
					<Button title="Remove Artifact" onClick={e=>{e.stopPropagation(); remove()}}
							className="shadow-none"/>
				</div>}
			</div>
		</Tippy>
	)
}

export default observer(ArtifactSlot);