// Data
import CONSTANTS from "../../data/CONSTANTS";

// Custom Components
import BonusProp from "../Hero/Effects/BonusProp";

// Utils
import {capitalize, isPredator} from "../../utilities";


const UnitInfo = ({
                      name,
                      base_atk,
                      base_hp,
                      extra,
                      roundNumber,
                      results,
                      bonus_atk,
                      bonus_def,
                      bonus_def_total,
                      bonus_hp,
                      army: {max_def}
                  }) => {

    const getExtraDescription = (name, extra) => {
        switch (name) {
            case CONSTANTS.ARMY.UNITS.CARRIER:
                return `Carries ${extra.carry} units of resources`
            case CONSTANTS.ARMY.UNITS.HEALER:
                const extraHeals = <BonusProp bonus={extra.extra_heals} name={CONSTANTS.EFFECTS.EXTRA_HEALS}/>
                const improvedHealing = <BonusProp bonus={extra.improved_healing}
                                                   name={CONSTANTS.EFFECTS.IMPROVED_HEALING}/>
                return <span>Revives {extra.revive} {extraHeals} {improvedHealing} units after battle</span>

            case CONSTANTS.ARMY.UNITS.MAGE:
                const improvedReduction = <BonusProp bonus={extra.improved_reduction}
                                                     name={CONSTANTS.EFFECTS.IMPROVED_MAGES}/>
                return <span>Reduces tower damage by {extra.reduceTowerDmg} {improvedReduction}</span>

            default:
                return ''
        }
    }

    let atkBonus = bonus_atk
    if (name === CONSTANTS.ARMY.UNITS.MAGE && !!roundNumber) {
        atkBonus += extra.inspiration[roundNumber]
    }

    const extraAbility = getExtraDescription(name, extra);

    let displayAtk = roundNumber !== undefined ? results.battle_round[roundNumber].atk : base_atk
    displayAtk = displayAtk.toString().replace(/(\d*)-(\d*)/g, '$1 - $2')

    return (
        <div className="unit_info">
            <p className='name'><i className={`unit-icon ${name}`}/> {capitalize(name)}</p>
            <p className="attack"><i className="utility-icon atk"/>
                {displayAtk} <BonusProp bonus={atkBonus} name='ATTACK'/>
            </p>
            <p className="defence"><i className="utility-icon def"/>
                0 {(!!bonus_def || (max_def === 0 && bonus_def_total > 0)) &&
                    <BonusProp bonus={bonus_def} name='DEFENCE' max={max_def}/>}
            </p>
            <p className="hp"><i className="utility-icon hp"/>
                {base_hp} <BonusProp bonus={bonus_hp} name='HP'/>
            </p>
            {extra && <p className="extra">{extraAbility}</p>}
            {isPredator(name) && <p className="extra"><i className="utility-icon predator"/>
                <BonusProp bonus={extra.predator_atk_bonus} name='PREDATOR_DAMAGE'/></p>}
        </div>
    )
}

export default UnitInfo;