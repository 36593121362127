// Libs
import {makeAutoObservable, toJS} from "mobx";

// Custom components
import EffectsStore from "./EffectsStore";

// Constants
import CONSTANTS from "../data/CONSTANTS";
import HEROES from "../data/HEROES";
import skill_assets from "../data/skill_assets.json";

// Utils
import {decodeEffect, deepClone, filterHeroEffects, getSkillSprite, makeAncientEffect} from "../utilities";
import artifactStore from "./ArtifactStore";


class HeroStore {
	heroClass = null;
	army = null
	gender = CONSTANTS.HERO.GENDER.MALE;
	position = null;
	specialization = null;
	race = null

	baseSkills = [];
	specializationSkills = [];
	advancedSkills = []
	extraSkills = []

	effects = new EffectsStore({hero: this});
	artifacts = null

	activeTab = 'hero_faces'

	constructor({race, army}) {
		makeAutoObservable(this)
		this.race = race
		this.army = army
		this.initialize()
	}

	initialize() {
		this.effects = new EffectsStore({hero: this});
		this.artifacts = {
			back: {},
			belt: {},
			footwear: {},
			helmet: {},
			item: {},
			necklace: {},
			ring: {},
			shield: {},
			thigh: {},
			weapon: {},
			wear: {},
			wrist: {},
			twoHanded: false
		};
		this.extraSkills = [
			{name: '', skills: []},
			{name: '', skills: []}
		]
	}

	pickHero(hero) {
		this.initialize()
		const {
			gender,
			heroClass,
			position,
			specialisation,
			baseSkills,
			specializationSkills,
			advancedSkills,
			emptySkillSlots,
			otherSpecializations
		} = hero;

		this.heroClass = heroClass
		this.gender = gender
		this.position = position
		this.specialization = specialisation
		this.emptySkillSlots = emptySkillSlots
		this.baseSkills = this.skillEnhance(baseSkills, 'base')
		this.specializationSkills = this.skillEnhance(specializationSkills, 'spec')
		this.advancedSkills = !!advancedSkills && this.skillEnhance(advancedSkills, 'advanced')
		this.extraSkills[0].skills = this.getEmptySkills()
		this.extraSkills[1].skills = this.getEmptySkills()
		this.otherSpecializations = otherSpecializations

		this.changeActiveTab('skills')
	}

	/**
	 * Change the tab in the hero modal
	 * @param newTab - name of the new tab
	 */
	changeActiveTab(newTab) {
		this.activeTab = newTab
	}

	/**
	 * Add all details to the skills
	 * @param skillsList
	 * @param type
	 * @returns {*}
	 */
	skillEnhance(skillsList, type) {
		return skillsList.map((sk, index) => {
			return {
				...sk, ...getSkillSprite(sk.NAME),
				lvl: type === "base" ? 1 : 0,
				type,
				updateLvl: this.updateSkillLvl.bind(this),
				id: index,
				maxLvl: type === "advanced" ? 1 : 5
			}
		})
	}

	getEmptySkills() {
		return [0, 0, 0, 0, 0, 0, 0].map((sk, index) => {
			return {
				sprite: skill_assets.skillSlots[this.emptySkillSlots],
				active: false,
				lvl: 1,
				id: index,
				maxLvl: 1
			}
		})
	}

	getSpecializations() {
		const currentSpecs = [this.specialization.toLowerCase()]
		this.extraSkills[0].name && currentSpecs.push(this.extraSkills[0].name)
		this.extraSkills[1].name && currentSpecs.push(this.extraSkills[1].name)
		const specList = Object.keys(this.otherSpecializations).map(x => x.toLowerCase())
		return specList.filter(x => !currentSpecs.includes(x))
	}

	setSpecialization(id, name) {
		this.extraSkills[id].name = name;
		this.extraSkills[id].skills = this.skillEnhance(this.otherSpecializations[name.toUpperCase()].SKILLS, 'spec')
	}

	removeSpecialization(id) {
		this.extraSkills[id].name = ''
		this.extraSkills[id].skills = this.getEmptySkills()
	}

	removeHero() {
		this.effects.removeAll()
		this.heroClass = null
		this.position = null
		this.specialization = null
		this.baseSkills = []
		this.specializationSkills = []
		this.advancedSkills = []
		this.initialize()
		this.changeActiveTab('hero_faces')
	}

	clickArtifactSlot(position) {
		if (!this.heroClass) return

		const artifactSlot = this.artifacts[position]
		const isEmptySlot = !artifactSlot.longName && !artifactSlot.grayed

		if (isEmptySlot) {
			artifactStore.filterByType(position)
			this.changeActiveTab('artifacts')
		} else {
			artifactStore.selectedArtifact = artifactSlot
			this.changeActiveTab('runes')
		}
	}

	putArtifactOn(artifact) {
		const newArtifact = deepClone(artifact)
		newArtifact.hero = this

		const {place, bothHands} = newArtifact
		const twoHanded = this.artifacts.twoHanded

		if (!twoHanded || place === 'weapon') {
			this.takeArtifactOff(place)
		}

		if (bothHands) {
			this.takeArtifactOff('shield')
			// this.artifacts.shield = grayArtifact(artifact)
			this.artifacts.shield = {position: newArtifact.position, grayed: true}
			this.artifacts.twoHanded = true
		} else if (place === 'weapon' && twoHanded) {
			this.artifacts.twoHanded = false
			this.takeArtifactOff('shield')
		}

		if (place === 'shield' && twoHanded) {
			alert('Can\'t put on shield while wearing two handed weapon. \nTake weapon off first.')
		} else {
			if (Array.isArray(newArtifact.effects)) {
				newArtifact.effects.forEach(e => {
					const eff = decodeEffect(e)
					this.effects.addEffect(eff)
				})
			}

			this.artifacts[place] = newArtifact
		}
	}

	putSetOn(artifactSet) {
		artifactSet.forEach(artifact => this.putArtifactOn(artifact))
	}

	updateArtifactEffects(place, isAncient) {
		const artifact = this.artifacts[place]

		if (!artifact.effects) return

		artifact.workingEffects.forEach(e => this.findAndRemoveEffect(e))

		if (isAncient) {
			const ancientEffects = artifact.effects.map(e => makeAncientEffect(e))
			artifact.workingEffects = deepClone(ancientEffects)
		} else {
			artifact.workingEffects = deepClone(artifact.effects)
		}

		artifact.workingEffects.forEach(e => this.effects.addEffect(decodeEffect(e)))
	}

	takeArtifactOff(position) {
		let effectsToRemove = this.artifacts[position].workingEffects
		if (Array.isArray(effectsToRemove)) {
			effectsToRemove.forEach(e => this.findAndRemoveEffect(e))
		}
		if (position === 'weapon' && this.artifacts.twoHanded) {
			this.artifacts.twoHanded = false
			this.takeArtifactOff('shield')
		}
		this.artifacts[position] = {}
	}

	/**
	 * Find effect in the effects store and remove it
	 * @param effect - encoded effect
	 */
	findAndRemoveEffect(effect) {
		const id = this.effects.findEffectId(decodeEffect(effect))
		this.effects.removeEffect(id)
	}

	/**
	 * Increase the skill level to the new level
	 * @param skill
	 * @param newLvl
	 */
	updateSkillLvl(skill, newLvl) {
		let minLvl = skill.type === 'base' ? 1 : 0;
		const {lvl, maxLvl} = skill

		if (newLvl !== undefined) {
			skill.lvl = newLvl
		} else {
			skill.lvl = lvl === maxLvl ? minLvl : lvl + 1
		}

		this.updateSkillEffects(skill, lvl)
	}

	/**
	 * When a skill level is changed, the effects of the old level are being removed
	 * and the new level effects are being added
	 * @param skill
	 * @param oldLvl
	 */
	updateSkillEffects(skill, oldLvl) {
		const oldEffects = filterHeroEffects([{...skill, lvl: oldLvl}])
		oldEffects.forEach(effect => this.findAndRemoveEffect(effect))

		const newEffects = filterHeroEffects([skill])
		newEffects.forEach(effect => this.effects.addEffect(decodeEffect(effect)))
	}

	/**
	 * Update the levels of all the skills of a branch
	 * @param branch
	 * @param levels
	 */
	skillBulkUpdate(branch, levels) {
		branch.forEach((skill, i) => this.updateSkillLvl(skill, levels[i]))
	}

	save() {
		const saveArtifacts = () => {
			const saved = {}
			for (const location of Object.keys(this.artifacts).filter(x => x !== 'twoHanded')) {
				saved[location] = this.artifacts[location].uid || false;
			}
			return saved
		}
		return !this.heroClass ? {class: false} : {
			class: this.heroClass,
			gender: this.gender,
			spec: {
				name: this.specialization,
				lvl: this.specializationSkills.map(sk => sk.lvl)
			},
			extraSkills: this.extraSkills.map(spec => ({name: spec.name, lvl: spec.skills.map(sk => sk.lvl)})),
			baseSkills: this.baseSkills.map(sk => sk.lvl),
			advancedSkills: this.advancedSkills.map(sk => sk.lvl),
			effects: this.effects.saveManual(),
			artifacts: saveArtifacts()
		}
	}

	load(h) {
		const heroes = HEROES.CLASSES.ALL[h.class]

		const newHero = {
			heroClass: h.class,
			specialisation: h.spec.name,
			gender: h.gender,
			position: heroes.SPECIALIZATIONS[h.spec.name].GENDERS[h.gender.toUpperCase()].POSITION,
			baseSkills: heroes.SKILLS,
			specializationSkills: heroes.SPECIALIZATIONS[h.spec.name].SKILLS,
			advancedSkills: heroes.ADVANCED_SKILLS,
			emptySkillSlots: heroes.EMPTY_SKILL_SLOTS,
			otherSpecializations: heroes.SPECIALIZATIONS
		}

		this.pickHero(newHero)

		this.skillBulkUpdate(this.baseSkills, h.baseSkills)
		this.skillBulkUpdate(this.specializationSkills, h.spec.lvl)
		this.skillBulkUpdate(this.advancedSkills, h.advancedSkills)

		h.extraSkills.forEach((branch, j) => {
			if (branch.name !== '') {
				this.setSpecialization(j, branch.name)
				this.skillBulkUpdate(this.extraSkills[j].skills, branch.lvl)
			}
		})

		// add manual effects
		h.effects.forEach(e => {
			const eff = decodeEffect(e)
			eff.manual = true
			this.effects.addEffect(eff)
		})

		for (const location of Object.keys(h.artifacts)) {
			if (h.artifacts[location]) {
				const artifact = artifactStore.findById(h.artifacts[location])
				if (artifact.type) {
					this.putArtifactOn(deepClone(artifact))
				} else {
					console.log('artifact not found')
				}
			}
		}


	}
}

export default HeroStore