// Libs
import {makeAutoObservable, toJS} from "mobx";

// Constants
import CONSTANTS from "../data/CONSTANTS";
import UNIT_PROPS from "../data/UNIT_PROPS";
import UNIT_SPRITES from "../data/UNIT_SPRITES";
import {BATTLE_STAGES} from "../data/BattleStages";
import LIMITS from "../data/LIMITS";
import {CUSTOM_ATTACK} from "../data/custom_attack";

// Stores
import HeroStore from "./HeroStore";

// Utils
import {
	encodeEffect,
	fitMinMax,
	fortuneAttack,
	getDmgPerUnit,
	getUnitAttack,
	isOppositeFaction,
	isPlayer,
	isMonster,
	isPredator,
	matchValues,
	spreadHeal,
	spreadKills, stripArmyName,
	sum
} from "../utilities";

class ArmyUnitsStore {
	units = []
	fighting = false;
	id = 0;
	race = CONSTANTS.ARMY.RACE.ELF;
	renegade = false;
	neutral = false;
	retreatAfter = 100;
	fraction = CONSTANTS.ARMY.FRACTION.LIGHT;
	hero = new HeroStore({race: this.race, army: this});
	receivedEffects = []
	hasUnits = false
	enemyFortifications = false
	savedArmies = []
	calcStore = {}
	enemies
	allies

	constructor({id, fighting, lvl, race, side}) {
		makeAutoObservable(this);
		lvl = lvl || 3
		this.id = id || 0
		this.fighting = fighting || false;
		this.race = race || this.race;
		this.side = side
		this.fortune = side === CONSTANTS.ARMY.SIDE.ATTACKER ? CONSTANTS.FORTUNE.MIN : CONSTANTS.FORTUNE.MAX

		for (const [key, unitName] of Object.entries(CONSTANTS.ARMY.UNITS)) {
			const base_atk = UNIT_PROPS[unitName].atk[lvl - 1]
			const [min_atk, max_atk] = this.initAttack(base_atk)
			this.units.push({
				key,
				name: unitName,
				army: this,
				lvl,
				base_atk,
				min_atk,
				max_atk,
				base_hp: UNIT_PROPS[unitName].hp[lvl - 1],
				extra: this.getUnitExtraAbility(unitName, lvl),
				race: this.race,
				sprite: UNIT_SPRITES[this.race][unitName][lvl - 1],
				count: 0,
				results: {
					battle_round: []
				}
			})
		}
		this.initBonuses()
		this.updateSavedArmies()
	}

	addUnitsEffect(effect, remove) {
		const amount = +effect.amount * (remove ? -1 : 1);

		switch (effect.type) {
			case CONSTANTS.EFFECTS.ATTACK:
			case CONSTANTS.EFFECTS.HP:
			case CONSTANTS.EFFECTS.DEFENCE:
				const mapEffect = {
					attack: 'bonus_atk',
					defence: 'bonus_def',
					hp: 'bonus_hp'
				}

				this.units.forEach(unit => {
					let max = effect.type === CONSTANTS.EFFECTS.DEFENCE ? this.max_def : undefined

					if (effect.units[unit.name]) {
						const bonusProp = mapEffect[effect.type]
						unit[bonusProp + '_total'] += amount;

						const lim = LIMITS[effect.type.toUpperCase()]
						unit[bonusProp] = fitMinMax(unit[bonusProp + '_total'], lim.min, max || lim.max)
					}
				})
				break;

			case CONSTANTS.EFFECTS.MAX_DEFENCE:
				this.max_def_total += amount
				const lim = LIMITS.DEFENCE
				this.max_def = fitMinMax(this.max_def_total, lim.min, lim.max)
				this.units.forEach(unit => {
					unit.bonus_def = fitMinMax(unit.bonus_def_total, null, this.max_def)
				})
				break;

			case CONSTANTS.EFFECTS.PREDATOR_DAMAGE:
				this.units.slice(1, 5).forEach(unit => {
					if (effect.units[unit.name]) {
						unit.extra.predator_atk_bonus_total += amount;
						const lim = LIMITS[effect.type.toUpperCase()]
						unit.extra.predator_atk_bonus = fitMinMax(unit.extra.predator_atk_bonus_total, lim.min);
					}
				})
				break;

			case CONSTANTS.EFFECTS.EXTRA_HEALS:
				this.units[5].extra.extra_heals += amount
				break;

			case CONSTANTS.EFFECTS.IMPROVED_HEALING:
				this.units[5].extra.improved_healing += amount
				break;

			case CONSTANTS.EFFECTS.IMPROVED_MAGES:
				this.units[7].extra.improved_reduction += amount
				break;

			case CONSTANTS.EFFECTS.ATTACK_AMPLIFICATION:
			case CONSTANTS.EFFECTS.EXTRA_ATTACK:
			case CONSTANTS.EFFECTS.MAGES_INSPIRATION:
			case CONSTANTS.EFFECTS.MAGES_DMG_REDUCTION:
			case CONSTANTS.EFFECTS.FORTIFICATIONS_EFFECTIVENESS:
				this[effect.type] += amount
				break;

			case CONSTANTS.EFFECTS.MAGIC_TOWER_ATTACK:
			case CONSTANTS.EFFECTS.TOWER_ATTACK:
				if (this.side === CONSTANTS.ARMY.SIDE.DEFENDER && this.id === 0) {
					this.calcStore.battlefieldSetup.towerBonuses[effect.type] += amount
				}
				break;

			case CONSTANTS.EFFECTS.NECROMANCY:
				for (const [key, value] of Object.entries(effect.parties)) {
					if (value) this.necromancyAmount[key] += amount
				}
				break;

			default:
		}
	}

	preAddEffect(effect) {
		effect.active = false
		if (this.checkEffectConditions(effect)) {
			effect.active = true
			this.addUnitsEffect(effect)
		}
	}

	checkEffectConditions(effect) {
		const mainDefender = this.calcStore.armies.defender[0]

		// mission check
		const attackMission = this.side === CONSTANTS.ARMY.SIDE.ATTACKER
		const missionPass = matchValues(effect.mission, {attack: attackMission, defense: !attackMission})

		// race check
		const currentRace = this.race === CONSTANTS.ARMY.RACE.CLAN_CASTLE ? CONSTANTS.ARMY.RACE.MONSTER : this.race
		const racePass = effect.race[currentRace]

		// terrain check
		const currentTerrain = this.calcStore.battlefieldSetup.terrain
		const terrainPass = effect.terrain[currentTerrain]

		// domain check
		const isClanCastle = mainDefender.race === CONSTANTS.ARMY.RACE.CLAN_CASTLE
		const isSaltLake = this.calcStore.battlefieldSetup.saltLake
		const domainPass = matchValues(effect.domain, {saltLake: isSaltLake, clanCastle: isClanCastle})

		/**
		 * ENEMY CHECK
		 *
		 * Initiator can also be an ally or self
		 * Enemy is on the other side, it could be the defender, or the attacker
		 * First determine who the enemy is
		 *
		 * Some effects regarding enemy are affecting myself, some affect the enemy itself
		 * So when checking the enemy, if the effect is targeting the enemy, then I am the enemy
		 * If the effect is targeting self, then it should check on the other side
		 * If the effect is targeting ally, then it should check on the other side too
		 *
		 * Could an effect targeting enemies be targeted on self and on enemy too?
		 * It shouldn't!
		 */

		const enemyInitiatedEffect = effect.parties.enemy && !effect.parties.self && !effect.parties.ally
		const enemy = enemyInitiatedEffect ? [effect.initiator] : this.enemies.filter(a => a.fighting)

		// const enemyIsMonster = enemy.some(army => isMonster(army.race))
		const enemyIsMonster = isMonster(enemy[0].race)

		const isSameRace = this.race === mainDefender.race
		const isSameFaction = this.fraction === mainDefender.fraction

		const isEnemyFaction = enemyInitiatedEffect
			? isOppositeFaction(this.fraction, enemy[0].fraction)
			: enemy.some(army => isOppositeFaction(this.fraction, army.fraction))

		const enemyPass = matchValues(effect.enemy, {
			monster: enemyIsMonster,
			enemyFaction: isEnemyFaction,
			sameFaction: isSameFaction,
			sameRace: isSameRace
		})

		// defender check
		const isRenegade = mainDefender.renegade
		const defenderPass = matchValues(effect.defender, {renegade: isRenegade, nonRenegade: !isRenegade})

		// self check
		const amIRenegade = this.renegade
		const selfPass = matchValues(effect.self, {renegade: amIRenegade, nonRenegade: !amIRenegade})

		return missionPass && racePass && terrainPass && domainPass && enemyPass && defenderPass && selfPass
	}

	reEvaluateEffects() {
		this.initBonuses(true)
		this.receivedEffects.forEach(effect => {
			this.preAddEffect(effect)
		})
	}

	resetEffects() {
		this.receivedEffects = []
		this.initBonuses()
	}

	initBonuses(resetTowers) {
		this.max_def_total = 50
		this.max_def = 50
		this.mages_dmg_reduction = 0
		this.mages_inspiration = 0
		this.attack_amplification = 0
		this.extra_attack = 0
		this.fortifications_effectiveness = 0
		this.necromancyAmount = {
			self: 0,
			ally: 0,
			enemy: 0
		}
		this.units.forEach(u => {
			u.bonus_atk = 0
			u.bonus_atk_total = 0
			u.bonus_hp = 0
			u.bonus_hp_total = 0
			u.bonus_def = 0
			u.bonus_def_total = 0
		})
		this.units.slice(1, 5).forEach(unit => {
			unit.extra.predator_atk_bonus_total = 0
			unit.extra.predator_atk_bonus = 0
		})

		this.units[5].extra.extra_heals = 0
		this.units[5].extra.improved_healing = 0
		this.units[7].extra.improved_reduction = 0
		this.units[7].extra.inspiration = new Array(20).fill(0)

		if (resetTowers) {
			this.calcStore.battlefieldSetup.towerBonuses = {
				magic_tower_attack: 0,
				tower_attack: 0
			}
		}
	}

	receivePresent(effect) {
		this.preAddEffect(effect)
		this.receivedEffects.push(effect)
	}

	cancelPresent(id) {
		const index = this.receivedEffects.findIndex(e => e.id === id)

		if (index > -1) {
			const effect = this.receivedEffects.splice(index, 1)[0]
			effect.active && this.addUnitsEffect(effect, true)
		} else {
			console.error('Could not find selected effect', id, toJS(this.receivedEffects))
		}
	}

	isScared() {
		const fearlessness = this.receivedEffects.some(effect => effect.type === CONSTANTS.EFFECTS.FEARLESSNESS)
		return fearlessness ? false : this.receivedEffects.some(effect => effect.type === CONSTANTS.EFFECTS.TERROR)
	}

	getUnitExtraAbility(unitName, unitLvl) {
		let extra = false;

		if (isPredator(unitName)) {
			extra = {
				predator_atk_bonus_total: 0,
				predator_atk_bonus: 0
			}
		} else if (UNIT_PROPS[unitName].EXTRA) {
			extra = {}
			const extraName = Object.keys(UNIT_PROPS[unitName].EXTRA)[0]
			extra[extraName] = UNIT_PROPS[this.race][unitName].EXTRA[extraName][unitLvl - 1]
			switch (unitName) {
				case CONSTANTS.ARMY.UNITS.HEALER:
					extra.extra_heals = 0
					extra.improved_healing = 0
					break;
				case CONSTANTS.ARMY.UNITS.MAGE:
					extra.improved_reduction = 0
					extra.inspiration = new Array(20).fill(0)
					break;
				default:
			}
		}

		return extra
	}

	initAttack(baseAtk) {
		const atk = baseAtk.split('-').map(n => +n)
		let min_atk, max_atk
		if (atk.length === 2) {
			[min_atk, max_atk] = atk
		} else {
			min_atk = atk[0]
			max_atk = atk[0]
		}
		return [min_atk, max_atk]
	}

	updateFortune(newVal) {
		this.fortune = newVal
	}

	updateAllUnitsLvl(newLvl) {
		for (let i = 0; i < 8; i++) {
			this.updateUnitLvl(i, newLvl);
		}
	}

	/**
	 * Update the level of the unit
	 * @param i - unit id
	 * @param newLvl
	 */
	updateUnitLvl(i, newLvl) {
		newLvl = newLvl === 5 ? 1 : newLvl
		const unit = this.units[i]
		const name = unit.name
		unit.lvl = newLvl;
		unit.base_atk = UNIT_PROPS[this.race][name].atk[newLvl - 1];
		unit.base_hp = UNIT_PROPS[this.race][name].hp[newLvl - 1];
		unit.sprite = UNIT_SPRITES[this.race][name][newLvl - 1];
		unit.extra = !!unit.extra && this.getUnitExtraAbility(name, newLvl);

		const base_atk = UNIT_PROPS[this.race][name].atk[newLvl - 1];
		const [min_atk, max_atk] = this.initAttack(base_atk)
		unit.base_atk = base_atk
		unit.min_atk = min_atk
		unit.max_atk = max_atk

		this.calcStore.getTowersDmg(0)
	}

	updateUnitCount(i, newCount) {
		this.units[i].count = +newCount;
		this.hasUnits = this.units.some(unit => unit.count > 0)
		this.calcStore.getTowersDmg(0)
	}

	clearArmy() {
		for (let i = 0; i < 8; i++) {
			this.updateUnitCount(i, 0)
		}
	}

	toggleRenegade(val) {
		this.renegade = val
		this.calcStore.reEvaluateAllEffects()
	}

	toggleNeutral(val) {
		this.neutral = val
	}

	updateRetreatPercent(newVal) {
		this.retreatAfter = newVal < 50 ? 50 : newVal > 100 ? 100 : newVal;
	}

	changeRace(newRace) {
		if ((!isPlayer(this.race) && isPlayer(newRace)) || (isPlayer(this.race) && !isPlayer(newRace))) {
			this.hero = new HeroStore({race: newRace, army: this})
		} else {
			this.hero.race = newRace;
		}
		this.race = newRace;
		this.fraction = UNIT_PROPS[this.race].fraction;
		for (let i = 0; i < 8; i++) {
			const unit = this.units[i]
			unit.race = this.race;
			unit.extra = !!unit.extra && this.getUnitExtraAbility(unit.name, unit.lvl);
			unit.sprite = UNIT_SPRITES[this.race][unit.name][unit.lvl - 1];

			const base_atk = UNIT_PROPS[this.race][unit.name].atk[unit.lvl - 1]
			const [min_atk, max_atk] = this.initAttack(base_atk)
			unit.base_atk = base_atk
			unit.min_atk = min_atk
			unit.max_atk = max_atk
		}

		this.calcStore.reEvaluateAllEffects()
	}

	getFortificationVictims() {
		const victims = this.units.map(unit => unit.results.current.initial)
		victims[6] = 0
		return victims
	}

	fortKills(kills) {
		const victims = this.getFortificationVictims()
		const killsPerUnit = spreadKills(victims, kills)
		this.units.forEach((unit, index) => {
			const initial = unit.results.current.initial
			const lost = Math.min(initial, killsPerUnit[index])
			unit.results.fortifications = {
				initial,
				gained: 0,
				lost
			}
			unit.results.current.initial = initial - lost
		})
	}

	resetTerror() {
		this.units.forEach(unit => {
			unit.results.terror = {
				initial: unit.count,
				gained: 0,
				lost: 0
			}
		})
	}

	flee() {
		if (this.isScared()) {
			const terrorAmount = Math.max(...this.receivedEffects
				.filter(effect => effect.type === CONSTANTS.EFFECTS.TERROR).map(effect => effect.amount))
			this.units.forEach(unit => {
				unit.results.terror = {
					initial: unit.count,
					gained: 0,
					lost: Math.floor(unit.count * terrorAmount / 100)
				}
				unit.results.current.initial = unit.count - Math.floor(unit.count * terrorAmount / 100)
				unit.results.current.left = unit.results.current.initial
			})
		} else {
			this.resetTerror()
		}
	}

	rejoin() {
		this.units.forEach(unit => {
			unit.results.rejoin = {
				initial: unit.results.current.initial,
				gained: unit.results.terror.lost,
				lost: 0
			}
			const initial = unit.results.current.initial + unit.results.terror.lost
			unit.results.current.initial = initial;
			unit.results.current.left = initial;
		})
		this.updateTotals()
	}

	necromancy({self, allies, enemies}) {
		console.log('necromancy settings', toJS(this.necromancyAmount))
		console.log('I am', this.side, this.id)
		for (const [key, value] of Object.entries(this.necromancyAmount)) {
			console.log(`${key}: ${value}`)
			if (value > 0) {
				switch (key) {
					case 'self':
						console.log('necro self')
						break

					case 'ally':
						console.log('necro ally')
						break

					case 'enemy':
						console.log('necro enemy')
						break

					default:
				}
			}
		}

	}

	initFight() {
		// console.log('init fight', toJS(this.units[7].extra))
		this.units[7].extra.inspiration = new Array(20).fill().map((e, i) => i * this.mages_inspiration)
		console.log('init fight', toJS(this.units[7].extra))

		this.units.forEach(unit => {
			//	initialize totals
			unit.results.summary = {
				initial: unit.count,
				gained: 0,
				lost: 0
			}
			// initialize current
			unit.results.current = {
				initial: unit.count,
				left: unit.count,
				lost: 0
			}
			unit.results.battle_round = []
		})
	}

	getTotalAttack(round) {
		let result = this.extra_attack
		let amplification = (100 + this.attack_amplification) / 100

		this.units.forEach((unit, index) => {
			const atk = this.fortune === CONSTANTS.FORTUNE.CUSTOM ?
				CUSTOM_ATTACK[this.side][this.id][round - 1][index] :
				fortuneAttack({min: unit.min_atk, max: unit.max_atk}, this.fortune)
			unit.results.current.atk = atk;
			result += getUnitAttack(unit, atk, round)
		})

		return result * amplification
	}

	getPredatorAttack() {
		return this.units.slice(1, 5)
			.map(unit => getUnitAttack(unit, unit.results.current.atk) * (100 + unit.extra.predator_atk_bonus) / 100)
	}

	getPreyUnitsAmount() {
		return this.units.slice(1, 5).map(unit => Math.ceil(unit.results.current.left))
	}

	getDmgCanHandle() {
		let total = 0
		this.units.forEach(unit => {
			total += unit.results.current.left * getDmgPerUnit(unit)
		})
		return total
	}

	postBattle() {
		this.units.forEach(unit => {
			let initial = Math.ceil(unit.results.current.initial)
			unit.results.battle_round.push({
				initial,
				gained: 0,
				lost: initial - Math.ceil(unit.results.current.left),
				atk: unit.results.current.atk
			})

			initial = unit.results.current.left
			unit.results.current = {
				initial,
				lost: 0,
				left: initial
			}
		})
	}

	simulateBattle(remainingDmg) {
		let remainingUnits = sum(this.units.map(unit => Math.ceil(unit.results.current.initial - unit.results.current.lost)))
		let count = 0

		while (remainingDmg > 0 && remainingUnits > 0 && count < 10) {
			count++
			const currentDmg = remainingDmg
			const currentUnits = remainingUnits

			remainingDmg = sum(this.units.map(unit => {
				const initial = unit.results.current.initial - unit.results.current.lost
				const dmgPartition = currentDmg * Math.ceil(initial) / currentUnits
				const enoughToKill = dmgPartition / getDmgPerUnit(unit)
				const dmgToKillAll = initial * getDmgPerUnit(unit);
				const lost = Math.min(initial, enoughToKill);

				unit.results.current.lost += +lost.toFixed(5)

				return Math.max(0, dmgPartition - dmgToKillAll)
			}))

			remainingUnits = sum(this.units.map(unit => Math.ceil(unit.results.current.initial - unit.results.current.lost)))
		}

		this.units.forEach(unit => {
			unit.results.current.left = +(unit.results.current.initial - unit.results.current.lost).toFixed(5)
		})

		if (this.side === 'attacker' && this.id === 0) {
			console.log('after battle round', toJS(this.units[1].results.current))
		}

		return +remainingUnits.toFixed(5)
	}

	predatorDmg(i, predatorDmg, totalPreyUnits, predatorAtk, preyUnits) {
		const unit = this.units[i + 1]

		const unitAmount = unit.results.current.left
		const dmgCanHandle = unitAmount * getDmgPerUnit(unit)
		const dmgParty = predatorDmg * Math.ceil(unitAmount) / totalPreyUnits
		const dealtDmg = Math.min(dmgParty, dmgCanHandle)

		const killedUnits = dealtDmg / getDmgPerUnit(unit)

		predatorAtk[i] -= dealtDmg
		preyUnits[i] -= killedUnits

		unit.results.current.lost += killedUnits;
		unit.results.current.left -= killedUnits;

		if (this.side === 'attacker' && this.id === 0 && i === 0) {
			console.log('predator dmg', {
				killedUnits,
				dealtDmg,
				dmgCanHandle,
				dmgParty,
				unitAmount,
				unitsCount: totalPreyUnits,
				unit
			})
		}
	}

	healRound() {
		const healer = this.units[5]
		const {revive, extra_heals, improved_healing} = healer.extra
		const healersAmount = Math.ceil(healer.results.current.initial)
		const bonus = (revive + extra_heals) * (100 + improved_healing) / 100
		const healAmount = Math.floor(healersAmount * bonus)
		const deadUnits = this.units.map(unit => unit.count - Math.ceil(unit.results.current.left) - unit.results.terror.lost)
		const healUnits = spreadHeal(deadUnits, healAmount)
		this.units.forEach((unit, index) => {
			unit.results[BATTLE_STAGES.HEAL] = {
				initial: Math.ceil(unit.results.current.initial),
				gained: healUnits[index],
				lost: 0
			}
			const initial = Math.ceil(unit.results.current.initial) + healUnits[index]
			unit.results.current.initial = initial;
			unit.results.current.left = initial;
		})
		this.updateTotals()
	}

	updateTotals() {
		this.units.forEach(unit => {
			unit.results.summary.lost = Math.floor(unit.count - unit.results.current.left)
		})
	}

	shouldRetreat() {
		const initial = sum(this.units.map(unit => unit.results.summary.initial))
		const current = sum(this.units.map(unit => Math.ceil(unit.results.current.left)))
		return 100 - 100 * current / initial >= this.retreatAfter
	}

	getDamageReduction() {
		return Math.max(0, Math.ceil(this.units[7].results.current.initial) * this.mages_dmg_reduction)
	}

	getTotalUnits() {
		return sum(this.units.map(unit => Math.ceil(unit.results.current.left)))
	}

	getMeanDamage(current) {
		return sum(this.units.map(unit => (current ? unit.results.current.left : unit.count) * ((unit.min_atk + unit.max_atk) / 2) * (100 + unit.bonus_atk) / 100))
	}

	/**
	 * Save the army to the local storage
	 */
	saveLocally() {
		const units = this.units.map(u => ({l: u.lvl, a: u.count}))
		const army = {units, race: this.race, hero: this.hero.save()}

		let res = true
		let armyName = ''

		do {
			armyName = prompt('give a name to the army')
			if (armyName === null) return // do not save if the cancel button is pressed

			// when there is already a saved army with the name, confirm overwrite
			if (this.savedArmies.map(a => stripArmyName(a)).includes(armyName)) {
				res = window.confirm('You already have an army with this name. \nOverwrite?')
			}
			// repeat asking for a name until the name is new or the overwrite is confirmed
		} while (res === false)

		armyName = CONSTANTS.ARMY.SAVED_ARMY_PREFIX + armyName
		localStorage.setItem(armyName, JSON.stringify(army))

		this.calcStore.updateSavedArmiesList()
	}

	/**
	 * Update the list of saved armies in the component memory
	 */
	updateSavedArmies() {
		this.savedArmies = Object.keys(localStorage).filter(a => a.startsWith(CONSTANTS.ARMY.SAVED_ARMY_PREFIX))
	}

	/**
	 * Load saved army from local storage
	 * @param name - the name of the saved army
	 */
	loadLocally(name) {
		const army = JSON.parse(localStorage.getItem(name))

		// remove all effects from all armies and reinitialize base effects
		this.calcStore.resetAllEffects()

		this.changeRace(army.race)

		// add units level and amount
		army.units.forEach((u, i) => {
			this.updateUnitLvl(i, u.l)
			this.updateUnitCount(i, u.a)
		})

		// load hero if there is one
		!!army.hero.class && this.hero.load(army.hero)

		// reevaluate all effects of all armies except this one
		this.calcStore.allArmies.filter(army => army !== this).forEach(a => a.hero.effects.reCalculateEffects())
	}

	removeSavedArmy(name) {
		localStorage.removeItem(name)
		this.calcStore.updateSavedArmiesList()
	}

	getDeadUnits() {
		return sum(this.units.map(unit => unit.results.summary.lost))
	}
}

export default ArmyUnitsStore
