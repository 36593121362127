import {Form} from "react-bootstrap";
import artifactStore from "../../../stores/ArtifactStore";
import ArtifactSlot from "./ArtifactSlot";
import {observer} from "mobx-react";

const EditArtifactTab = () => {
	const runeWordsLink = "https://docs.google.com/spreadsheets/d/1-qk1iFUNHslE25zoMoV" +
		"6fkBf4c_jUwLJtS1BTsZaI1Y/edit#gid=590245945"
	return (
		<div id="edit_artifact_tab">
			<div>
				<div id="artifact_long_name">{artifactStore.selectedArtifact.longName}</div>

				<div className="show_artifact">
					<div className="artifact_slot">
						<ArtifactSlot artifact={artifactStore.selectedArtifact} clickMe={()=>{}}/>
					</div>

					<div className="checkboxes">
						<Form>
							<Form.Check
								label="Ancient"
								type="checkbox"
								disabled={artifactStore.selectedArtifact.nonAncient}
								id="artifact_is_ancient"
                                checked={artifactStore.selectedArtifact.isAncient}
								onChange={e => artifactStore.toggleAncient(e.target.checked)}
							/>
							<Form.Check
								label="Perfect"
								type={"checkbox"}
								id="artifact_is_perfect"
								checked={artifactStore.selectedArtifact.isUpgraded}
								onChange={e => artifactStore.togglePerfect(e.target.checked)}
							/>
						</Form>
					</div>
				</div>


				<br/>
				<div>Runes and Enchantments will be here too</div>
				{/*<div>Display runes edit section</div>*/}
				{/*<div>Under the runes should be a section to add the effect of the rune word</div>*/}
				{/*<div>The known rune words can be found on the following link:*/}
				{/*	<a href={runeWordsLink} target="_blank">Rune Words</a>*/}
				{/*</div>*/}

				{/*<br/>*/}
				{/*<div>Enchantments. Allow modification of the base parameter.</div>*/}
				{/*<div>In order to make enchantments easy, there should be a select to select the*/}
				{/*	available enchantment for the selected artifact. All available enchantments are on the wiki page:*/}
				{/*	<a href="https://mlgame.co.uk/wiki-uk/Wiki.jsp?page=Alchemy#" target="_blank">Alchemy</a>*/}
				{/*</div>*/}
				{/*<h2>Work in progress...</h2>*/}

			</div>
		</div>
	)
}

export default observer(EditArtifactTab)