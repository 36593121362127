// Libs
import {observer} from "mobx-react";

// Custom
import {capitalize, stripArmyName, unSnake} from "../../utilities";
import calculatorStore from "../../stores/CalculatorStore";
import CONSTANTS from "../../data/CONSTANTS";
import {OverlayTrigger, Popover} from "react-bootstrap";

const ArmySettings = ({side, index, army}) => {
    const {units, renegade, neutral, retreatAfter} = army;
    let retreatInputTimeout;

    const eachUnitLvl = units.map(unit => unit.lvl);
    const allUnitsLvl = eachUnitLvl.every((val, i, arr) => val === arr[0]) ? eachUnitLvl[0] : 0;

    const allRaces = Object.values(CONSTANTS.ARMY.RACE);

    const updateRetreat = e => {
        clearTimeout(retreatInputTimeout)
        retreatInputTimeout = setTimeout(() => {
            const val = e.target.value
            e.target.value = val < 50 ? 50 : val > 100 ? 100 : val
            army.updateRetreatPercent(e.target.value)
        }, 1000)
    }

    return (
        <div className="army_settings">

            <div className="army_side">
                {capitalize(side)} {index + 1}
            </div>

            <div className="army_race">
                <select name="" id=""
                        className="form-control custom-select"
                        defaultValue={army.race}
                        onChange={e => army.changeRace(e.target.value)}>
                    {allRaces.map((race, index) => <option key={index} value={race}>{unSnake(race)}</option>)}
                </select>
            </div>

            <div className="army_options_checkboxes">
                <div className="option_checkbox">
                    <input type="checkbox"
                           id={`renegade_${side}_${index}`}
                           defaultValue={renegade}
                           defaultChecked={renegade}
                           onChange={(e) => army.toggleRenegade(e.target.value)}/>
                    <label htmlFor={`renegade_${side}_${index}`}>Renegade</label>
                </div>
                <div className="option_checkbox">
                    <input type="checkbox"
                           id={`neutral_army_${side}_${index}`}
                           defaultValue={neutral}
                           defaultChecked={neutral}
                           onChange={(e) => army.toggleNeutral(e.target.value)}/>
                    <label htmlFor={`neutral_army_${side}_${index}`}>Neutral</label>
                </div>
            </div>

            <div className="army_all_units_level">
                <p>Level of all units</p>
                <div className="units_levels">
                    {[1, 2, 3, 4].map(lvl => (
                        <button className={`${lvl === allUnitsLvl ? 'selected' : ''}`}
                                onClick={() => army.updateAllUnitsLvl(lvl)}
                                key={lvl}>
                            {lvl}
                        </button>))}
                </div>
            </div>

            <div className="army_retreat">
                <p>Retreat:</p>
                <input type="number"
                       defaultValue={retreatAfter}
                       onChange={e => updateRetreat(e)}
                       min="50" max="100"/>
            </div>

            <div className="army_fortune">
                <p>Fortune</p>
                <select className="form-control" defaultValue={army.fortune}
                        onClick={e => {
                            army.updateFortune(e.target.value)
                        }}>
                    {process.env.REACT_APP_LOCAL_DEV === 'true' ?
                        Object.values(CONSTANTS.FORTUNE)
                            .map(fortune => <option key={fortune} value={fortune}>{fortune}</option>)
                        : Object.values(CONSTANTS.FORTUNE).filter(x => x !== 'custom')
                            .map(fortune => <option key={fortune} value={fortune}>{fortune}</option>)}
                </select>
            </div>

            <div className="clear_army">
                <button onClick={() => army.clearArmy()} title='clear army'/>
            </div>

            <div className="save_army">
                <button className='save_army_btn' onClick={() => army.saveLocally()} title='save army'/>
            </div>

            <div className="load_army">
                <OverlayTrigger trigger='click' rootClose placement='bottom' overlay={
                    <Popover id='skill_button_popover'>
                        <Popover.Body>
                            {army.savedArmies.map((a, i) => (
                                <p className="extraSpecialization" key={i}>
                                    <span className="remove" onClick={() => {
                                        army.removeSavedArmy(a)
                                        document.body.click()
                                    }}/>
                                    <span className='name saved_army_name' onClick={() => {
                                        army.loadLocally(a)
                                        document.body.click()
                                    }}>{stripArmyName(a)}</span>
                                </p>)
                            )}
                        </Popover.Body>
                    </Popover>
                }>
                    <button title='load army'/>
                </OverlayTrigger>
            </div>

            {index !== 0 && (<div className="remove_army" onClick={() => calculatorStore.removeArmy(side, index)}/>)}
        </div>
    )
}

export default observer(ArmySettings);