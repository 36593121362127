// Libs
import {observer} from "mobx-react";
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'

// Utils
import {getSprite} from "../../../utilities";

// Custom Components
import SkillInfo from "./SkillInfo";

const Skill = ({props}) => {

	const {active, sprite, lvl, updateLvl, maxLvl, DESCRIPTION, EFFECTS, STATS} = props;
	let imageStyle = getSprite(sprite)

	const infoProps = {DESCRIPTION, EFFECTS, STATS, lvl, active}

	const clickSkill = () => {
		props.NAME && updateLvl(props)
	}

	return (
		<Tippy placement="right" arrow={false} theme={'army-unit'} disabled={!DESCRIPTION} content={<SkillInfo {...infoProps}/>}>
			<div className={`skill_container ${props.NAME ? "" : "disabled"}`} onClick={clickSkill}>
				<div className={`skill_border ${active ? "active" : "passive"}`}/>
				<div className={`skill_image ${lvl === 0 ? 'disabled' : ''}`} style={imageStyle}/>
				{props.NAME && <div className={`skill_lvl ${lvl === maxLvl ? 'maxed' : ''}`}>
					<div className="counter">{lvl}/{maxLvl}</div>
				</div>}
			</div>
		</Tippy>
	)
}

export default observer(Skill);