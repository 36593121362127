// Utils
import {decodeEffect, skillInfoStat, filterEffect} from "../../../utilities";
import EffectLabel from "../Effects/EffectLabel";


const SkillInfo = (props) => {
    const {DESCRIPTION, STATS, lvl, EFFECTS, active} = props

    if (!STATS) return 'No Effect'

    let stats = STATS
    if (!Array.isArray(STATS[0])) {
        stats = STATS.map(s => [s])
    }
    const stat = +lvl === 0 ? new Array(stats[0].length).fill(0) : stats[lvl - 1]

    if (!EFFECTS) {
        return (
            <div className="unit_info">
                {DESCRIPTION && skillInfoStat(DESCRIPTION, stat)}
            </div>
        )
    } else {
        const effects = filterEffect(EFFECTS, stat).map(e => {
            const eff = decodeEffect(e)
            eff.active = active
            return eff
        })
        return (
            <div className="unit_info">
                {effects.map((e, i) => <EffectLabel key={i} effect={e}/>)}
            </div>
        )
    }
}

export default SkillInfo;