// Libs
import {observer} from "mobx-react";

// Data
import CONSTANTS from "../../data/CONSTANTS";
import {BATTLE_STAGES} from "../../data/BattleStages";

// Store
import calculatorStore from "../../stores/CalculatorStore";

// Custom Components
import HeroFaceComponent from "../Hero/Modal/HeroFaceComponent";
import UnitNonEditable from "./UnitNonEditable";

// Utils
import {capitalize, isTower} from "../../utilities";


const ArmyResults = ({side, army, roundNumber, round}) => {
    const {units, hero} = army;
    const heroFaceProps = {
        hero,
        race: army.race
    }

    const isTerrorRound = round === BATTLE_STAGES.TERROR
    const isFortificationsRound = round === BATTLE_STAGES.FORTIFICATIONS
    const isBattleSummary = round === BATTLE_STAGES.SUMMARY
    const isBattleRound = round === BATTLE_STAGES.BATTLE_ROUND
    const isAttacker = side === CONSTANTS.ARMY.SIDE.ATTACKER
    const isDefender = side === CONSTANTS.ARMY.SIDE.DEFENDER
    const armyScared = army.isScared()
    const unitsKilledAtFortifications = army.enemyFortifications
    const defenderHasTowers = calculatorStore.battlefieldSetup.fortifications.some(fort => isTower(fort.type))

    const shouldDisplayArmy = (isTerrorRound && armyScared && army.hasUnits) ||
        (!isTerrorRound && !isFortificationsRound && army.hasUnits) ||
        (!isTerrorRound && isAttacker) ||
        (isFortificationsRound && unitsKilledAtFortifications) ||
        (isBattleRound && (army.hasUnits || (isDefender && defenderHasTowers))) ||
        isBattleSummary

    const showTurrets = isBattleRound && side === CONSTANTS.ARMY.SIDE.DEFENDER && army.id === 0;

    return shouldDisplayArmy ? (
        <div className="army">
            <div className='army_title'>
                {capitalize(side)} {army.id + 1}
            </div>
            <div className="row army_units">
                <HeroFaceComponent {...heroFaceProps}/>
                <div className="row units">
                    {units.map(unit => (
                        <UnitNonEditable {...unit} type="unit" stage={round} roundNumber={roundNumber}/>
                    ))}

                    {showTurrets &&
                        calculatorStore.battleResults.towers[roundNumber].filter(t => t.lvl > 0)
							.map((tower, id) => <UnitNonEditable type="turret"
																 key={id}
																 towerId={id}
																 fort={tower}
																 race={army.race}
																 roundNumber={roundNumber}/>)
                    }

                </div>
            </div>
            {/*{isBattleSummary && (*/}
            {/*		<div className="other_details">*/}
            {/*			Fraction rating: 500*/}
            {/*		</div>*/}
            {/*)}*/}
        </div>
    ) : <></>
}

export default observer(ArmyResults);