// Libs
import {observer} from "mobx-react";

// Custom Components
import BattleResults from "../BattleResults/BattleResults";
import ArmiesInputs from "../Army/ArmiesInputs";

// Data objects
import {Button} from "react-bootstrap";
import calculatorStore from "../../stores/CalculatorStore";

const Calculator = () => (
	<div className="dialog calculator_border">
		<ArmiesInputs/>
		<div className="d-grid gap-2 fight-button">
			<Button variant="info" size="lg" className="shadow-none" id='fight_button' onClick={() => calculatorStore.fight()}>
				Fight
			</Button>
		</div>
		<BattleResults/>
	</div>
)


export default observer(Calculator);