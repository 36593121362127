import {Button, OverlayTrigger, Popover} from "react-bootstrap";

const SkillBranchButton = ({id, name, hero}) => {
    const setSpec = (sk) => {
        hero.setSpecialization(id, sk)
        document.body.click()
    }

    const removeSpec = () => {
        const specIsSet = hero.extraSkills[id].name !== ''
        return specIsSet &&
            <p className='extraSpecialization remove' onClick={() => {
                hero.removeSpecialization(id);
                document.body.click()
            }}>remove</p>
    }

    return (
        <div className="skill_branch_button">
            <OverlayTrigger
                trigger="click"
                placement="bottom"
                rootClose
                overlay={
                    <Popover id={`skill_button_popover`}>
                        <Popover.Body>
                            {hero.getSpecializations()
                                .map((sk, i) =>
                                    <p className="extraSpecialization" key={i} onClick={() => {
                                        setSpec(sk);
                                    }}>{sk}</p>)}
                            {removeSpec()}
                        </Popover.Body>
                    </Popover>
                }>
                <Button variant="info" className="sk-btn shadow-none">{name ? <span>{name}</span> : `[+]`}</Button>
            </OverlayTrigger>
        </div>
    )
}

export default SkillBranchButton;