// Libs
import {observer} from "mobx-react";

// Custom Components
import Unit from "./Unit";
import ArmySettings from "./ArmySettings";
import HeroModal from "../Hero/Modal/HeroModal";
import HeroFaceComponent from "../Hero/Modal/HeroFaceComponent";

const Army = ({side, army}) => {
	const {race, units, hero} = army;

	const heroModalProps = {
		side,
		race,
		hero
	}

	const armySettingsProps = {
		side,
		army,
		index: army.id,
	}

	const heroFaceProps = {
		hero,
		race: army.race
	}

	return (
			<div className="army">
				<ArmySettings {...armySettingsProps}/>
				<div className="row army_units">
					<HeroFaceComponent {...heroFaceProps}>
						<HeroModal {...heroModalProps}/>
					</HeroFaceComponent>
					<div className="row units">
						{units.map((unit, index) => (
								<Unit {...unit}
								      updateUnitLvl={(newLvl)=>army.updateUnitLvl(index, newLvl)}
								      updateCount={(newCount)=>army.updateUnitCount(index, newCount)}/>
						))}
					</div>
				</div>
			</div>
	)
}

export default observer(Army);