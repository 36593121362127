import ArmyUnitsStore from "../stores/ArmyUnitsStore";
import CONSTANTS from "./CONSTANTS";

const attacker = CONSTANTS.ARMY.SIDE.ATTACKER
const defender = CONSTANTS.ARMY.SIDE.DEFENDER

export const INITIAL_CALC_STATE = {
	armies: {
		attacker: [
			new ArmyUnitsStore({id: 0, side: attacker, fighting: true}),
			new ArmyUnitsStore({id: 1, side: attacker}),
			new ArmyUnitsStore({id: 2, side: attacker}),
		],
		defender: [
			new ArmyUnitsStore({id: 0, side: defender, fighting: true}),
			new ArmyUnitsStore({id: 1, side: defender}),
			new ArmyUnitsStore({id: 2, side: defender}),
			new ArmyUnitsStore({id: 3, side: defender}),
		]
	},
	battlefieldSetup: {
		maxDefence: false,
		defenderSpecLvl: 0,
		saltLake: false,
		terrain: CONSTANTS.TERRAIN.MAGIC_FORREST,
		newFortification: {
			lvl: 1,
			type: CONSTANTS.DEF_BUILDINGS.FORTIFICATION,
			noMoreTowers: false,
			noMoreGates: false
		},
		fortifications: [],
		towerDamage: [],
		towersLvl: {},
		towerBonuses: {
			magic_tower_attack: 0,
			tower_attack: 0
		}
	},
	battleResults:{
		attacker: [
			new ArmyUnitsStore({id: 0, fighting: true}),
			new ArmyUnitsStore({id: 1}),
			new ArmyUnitsStore({id: 2}),
		],
		defender: [
			new ArmyUnitsStore({id: 0, fighting: true}),
			new ArmyUnitsStore({id: 1}),
			new ArmyUnitsStore({id: 2}),
			new ArmyUnitsStore({id: 3}),
		]
	}
}