const LIMITS= {
	ATTACK: {
		min: -90
	},
	DEFENCE: {
		min: 0,
		max: 75
	},
	HP: {
		min: -75
	},
	PREDATOR_DAMAGE: {
		min: -90
	}
}

export default LIMITS