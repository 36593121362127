// Libs
import {observer} from "mobx-react";

// Custom
import Army from "../Army/Army";
import AddArmy from "../Army/AddArmy";
import calculatorStore from "../../stores/CalculatorStore";
import ArmyResults from "../BattleResults/ArmyResults";


const BattleSide = ({side, type, round, roundNumber}) => {
	switch (type) {
		case "input": {
			let shownArmies = 0
			calculatorStore.armies[side].forEach(army => shownArmies += army.fighting ? 1 : 0)
			return (
					<div className={`${side}s-side`}>
						{calculatorStore.armies[side]
								.map(army => army.fighting && (<Army key={army.id} side={side} army={army}/>))}
						{shownArmies < calculatorStore.armies[side].length && (<AddArmy side={side}/>)}
					</div>
			)
		}
		case "results": {
			const resultProps = {type, round, side, roundNumber}
			return calculatorStore.battleResults[side]
					? (<div className={`${side}s-side`}>
						{calculatorStore.battleResults[side].map(army => <ArmyResults key={army.id} {...resultProps} army={army}/>)}
					</div>)
					: null
		}
		default: {
			return <h3>your case not found</h3>
		}
	}
}

export default observer(BattleSide);