// Libs
import {makeAutoObservable, toJS} from "mobx";
import {v4 as uuidv4} from 'uuid';

// Custom
import {INITIAL_CALC_STATE} from "../data/INITIAL_CALC_STATE";
import CONSTANTS from "../data/CONSTANTS";
import UNIT_PROPS from "../data/UNIT_PROPS";
import {BattlefieldEffects} from "../data/BattlefieldEffects";
import {
	compareObjectValues,
	decodeEffect,
	deepClone,
	fortuneAttack,
	isPlayer,
	isTower, reduceTowers,
	spreadKills,
	sum,
	sumArrays
} from "../utilities";

class CalculatorStore {
	armies = INITIAL_CALC_STATE.armies
	battlefieldSetup = INITIAL_CALC_STATE.battlefieldSetup
	winner = CONSTANTS.ARMY.SIDE.DEFENDER
	battleEnded = false
	battleResults = {
		stages: {
			terror: {
				active: false
			},
			fortifications: {
				active: true
			},
			battle_round: [],
			heal: {
				active: false
			},
			rejoin: {
				active: false
			}
		},
		towers: []
	}

	constructor() {
		makeAutoObservable(this)
		this.armies.attacker.forEach((army, index) => {
			army.enemies = this.armies.defender
			army.allies = this.armies.attacker.filter(a => a.id !== index)
			army.calcStore = this
		})
		this.armies.defender.forEach((army, index) => {
			army.enemies = this.armies.attacker
			army.allies = this.armies.defender.filter(a => a.id !== index)
			army.calcStore = this
		})
		this.allArmies = [...this.armies.attacker, ...this.armies.defender]
		this.initBattlefieldEffects()
	}

	initBattlefieldEffects() {
		BattlefieldEffects.forEach(effect => {
			this.allArmies.forEach(army => army.receivePresent(decodeEffect(effect)))
		})
	}

	addArmy(side) {
		const firstHidden = this.armies[side].findIndex(army => army.fighting === false)
		this.armies[side][firstHidden].fighting = true;
	}

	removeArmy(side, index) {
		this.armies[side][index].fighting = false;
	}

	toggleMaxDefence() {
		this.battlefieldSetup.maxDefence = !this.battlefieldSetup.maxDefence
	}

	toggleSaltLake() {
		this.battlefieldSetup.saltLake = !this.battlefieldSetup.saltLake
		this.reEvaluateAllEffects()
	}

	updateDefSpecLvl(newLvl) {
		this.battlefieldSetup.defenderSpecLvl = newLvl;
		this.reEvaluateFortificationsDefence()
	}

	updateNewFort(type) {
		this.battlefieldSetup.newFortification.type = type
	}

	updateNewFortLvl(newLvl) {
		this.battlefieldSetup.newFortification.lvl = newLvl;
	}

	changeTerrain(newTerrain) {
		this.battlefieldSetup.terrain = newTerrain
		this.reEvaluateAllEffects()
	}

	reEvaluateAllEffects() {
		this.allArmies.forEach(a => a.reEvaluateEffects())
	}

	resetAllEffects() {
		this.allArmies.forEach(a => a.resetEffects())
		this.initBattlefieldEffects()
		this.battlefieldSetup.fortDefEffectId = null
		this.reEvaluateFortificationsDefence()
	}


	addDefBuilding() {
		const newFort = this.battlefieldSetup.newFortification
		const fortsList = this.battlefieldSetup.fortifications
		const {type, lvl} = newFort
		const fortification = CONSTANTS.DEF_BUILDINGS.FORTIFICATION
		const newIsTower = isTower(type)
		const isGate = type === CONSTANTS.DEF_BUILDINGS.GATES

		if ((newFort.noMoreTowers && newIsTower)
			|| (newFort.noMoreGates && isGate)) return

		let towersAmount = 0
		fortsList.forEach(building => {
			if (isTower(building.type)) {
				towersAmount += building.count
			}
		})

		const similarBuildingId = fortsList.findIndex(bd => compareObjectValues(bd, {type, lvl}))

		if (newIsTower) {
			if (towersAmount < 2) {
				if (similarBuildingId > -1) {
					fortsList[similarBuildingId].count++;
				} else {
					const newTower = {type, lvl, count: 1}

					if (fortsList.length > 0 && fortsList[0].type === CONSTANTS.DEF_BUILDINGS.GATES) {
						if (fortsList.length > 1 && isTower(fortsList[1].type) && fortsList[1].lvl > lvl) {
							fortsList.splice(2, 0, newTower)
						} else {
							fortsList.splice(1, 0, newTower)
						}
					} else {
						if (fortsList.length > 0 && isTower(fortsList[0].type) && fortsList[0].lvl > lvl) {
							fortsList.splice(1, 0, newTower)
						} else {
							fortsList.unshift(newTower)
						}
					}
				}

				if (towersAmount > 0) {
					newFort.noMoreTowers = true
					newFort.type = fortification
				}

			} else {
				newFort.noMoreTowers = true
				newFort.type = fortification
			}
		} else if (isGate) {
			fortsList.unshift({type, lvl, count: 1})
			newFort.noMoreGates = true
			newFort.type = fortification
		} else {
			if (similarBuildingId > -1) {
				fortsList[similarBuildingId].count++;
			} else {
				// todo: put them in order, find the last that's not lower level
				fortsList.push({type, lvl, count: 1})
			}

			this.reEvaluateFortificationsDefence()
		}

		this.getTowersDmg(0)
	}

	reEvaluateFortificationsDefence() {
		let defAmount = 0
		const defSpecLvl = this.battlefieldSetup.defenderSpecLvl,
			fortDefs = UNIT_PROPS.playerBuildings.fortification.extra_defence

		this.battlefieldSetup.fortifications
			.filter(building => building.type === CONSTANTS.DEF_BUILDINGS.FORTIFICATION)
			.forEach(f => {
				defAmount += (fortDefs[f.lvl - 1] + defSpecLvl) * f.count
			})

		const effectStr = `defence.${defAmount}.4.255.63.511.4.16.4.4.4`
		const effect = decodeEffect(effectStr)
		const currentEffectId = this.battlefieldSetup.fortDefEffectId

		effect.id = uuidv4()

		currentEffectId && this.armies.defender[0].cancelPresent(currentEffectId)
		if (defAmount > 0) {
			this.armies.defender[0].receivePresent(effect)
			this.battlefieldSetup.fortDefEffectId = effect.id
		} else {
			this.battlefieldSetup.fortDefEffectId = null
		}

		this.getTowersDmg(0)
	}

	removeDefBuilding(building) {
		const {type, lvl} = building
		const fortsList = this.battlefieldSetup.fortifications
		const newFort = this.battlefieldSetup.newFortification
		newFort.type = type
		newFort.lvl = lvl
		const isGate = type === CONSTANTS.DEF_BUILDINGS.GATES
		const buildingId = fortsList.findIndex(bd => compareObjectValues(bd, {type, lvl}))

		const selectedFort = fortsList[buildingId]

		if (selectedFort.count === 1) {
			fortsList.splice(buildingId, 1)
		} else {
			selectedFort.count--
		}

		if (isTower(type)) {
			newFort.noMoreTowers = false
		} else if (isGate) {
			newFort.noMoreGates = false
		} else {
			this.reEvaluateFortificationsDefence()
		}
	}

	countAttackingCatapults() {
		let count = 0
		this.armies.attacker.forEach(army => {
			count += army.fighting ? army.hero.effects.hasEffect(CONSTANTS.EFFECTS.CATAPULT) : 0
		})
		return count
	}

	getTowers(towers) {
		if (towers.length < 1) return [{lvl: 0}, {lvl: 0}]

		if (towers.length === 1) {
			const t = towers[0]
			if (towers[0].count > 1) {
				return [{lvl: t.lvl, type: t.type}, {lvl: t.lvl, type: t.type}]
			} else {
				return [{lvl: t.lvl, type: t.type}, {lvl: 0}]
			}
		}

		return towers.map(t => ({lvl: t.lvl, type: t.type}))
	}

	getTowersDmg(round) {
		const host = this.armies.defender[0]
		const hostBuildings = isPlayer(host.race) ? 'playerBuildings' : 'monsterBuildings'
		const defSpecLvl = this.battlefieldSetup.defenderSpecLvl
		const dmgAmpDefSpec = [0, 50, 75, 100][defSpecLvl]
		const catapultsCount = this.countAttackingCatapults()

		const towers = [0, 1].includes(round) ? this.battlefieldSetup.fortifications.filter(f => isTower(f.type))
			: this.battleResults.towers[round - 2]
		const towersList = this.getTowers(towers)
		const towerLevels = towersList.map(t => t.lvl)
		const newTowerLevels = reduceTowers(...towerLevels, catapultsCount)
		const towersBattle = this.battleResults.towers
		const newTowers = []
		let totalDmg = 0

		towersList.forEach((tower, index) => {
			const lvl = newTowerLevels[index]
			const newTower = {lvl, type: tower.type, count: 1}

			if (lvl > 0) {
				const towerDmg = UNIT_PROPS[hostBuildings][tower.type][lvl - 1]

				if (tower.type === CONSTANTS.DEF_BUILDINGS.TOWER) {
					const [minDmg, maxDmg] = towerDmg.split('-').map(x => +x)
					const bonus = (100 + dmgAmpDefSpec + this.battlefieldSetup.towerBonuses.tower_attack) / 100
					const dmg = fortuneAttack({min: minDmg, max: maxDmg}, host.fortune) * bonus
					newTower.dmg = dmg
					totalDmg += dmg
				} else {
					const bonus = (100 + this.battlefieldSetup.towerBonuses.magic_tower_attack) / 100
					const dmg = towerDmg * host.getMeanDamage(round !== 0) * bonus
					newTower.dmg = dmg
					totalDmg += dmg
				}
			}

			newTowers.push(deepClone(newTower))
		})

		if (round === 0) {
			this.battlefieldSetup.towerDamage = newTowers
		} else {
			towersBattle.push(deepClone(newTowers))
		}

		return totalDmg
	}

	partyBattle(party, totalAtk, predatorAtk) {

		//main damage
		let totalUnits = party.map(army => army.getTotalUnits())
		if (party[0].side === 'attacker') {
			console.log('total dmg', totalAtk, predatorAtk[1], totalUnits)
		}
		let safety = 0
		do {
			safety++;
			const maxDmgPerArmy = party.map(army => army.getDmgCanHandle())
			const totalUn = totalUnits
			const totalUnitsAmount = sum(totalUn)
			const totalDmg = totalAtk
			let dmgLeft = 0
			let dmgPerArmy = party.map((army, index) => {
				const armyUnits = totalUn[index]
				const dmgPartition = totalUnitsAmount === 0 ? 0 : armyUnits * totalDmg / totalUnitsAmount
				dmgLeft += dmgPartition > maxDmgPerArmy[index] ? dmgPartition - maxDmgPerArmy[index] : 0
				return Math.min(dmgPartition, maxDmgPerArmy[index])
			})
			if (party[0].side === 'attacker') {
				console.log({dmgPerArmy: dmgPerArmy[0]})
			}
			totalAtk = dmgLeft
			totalUnits = party.map((army, index) => army.simulateBattle(dmgPerArmy[index]))
		} while (totalAtk > 0 && totalUnits > 0 && safety < 10)


		// predator damage
		predatorAtk.push(predatorAtk.shift())
		const preyUnits = sumArrays(party.map(army => army.getPreyUnitsAmount()))

		for (let i = 0; i < 4; i++) {
			let safetyCounter = 0
			while (preyUnits[i] > 0 && predatorAtk[i] > 0 && safetyCounter < 5) {
				safetyCounter++;
				const predatorDmg = predatorAtk[i]
				const unitsCount = preyUnits[i]
				party.forEach(army => army.predatorDmg(i, predatorDmg, unitsCount, predatorAtk, preyUnits))
			}
		}

		totalUnits = sum(party.map(army => army.getTotalUnits()))
		party.forEach(army => army.postBattle())
		return totalUnits === 0
	}

	partyFortificationKills(party, kills) {
		if (kills > 0) {
			const victims = party.map(army => sum(army.getFortificationVictims()))
			const killsPerArmy = spreadKills(victims, kills)
			party.forEach((army, index) => {
				army.enemyFortifications = true
				army.fortKills(killsPerArmy[index])
			})
		} else {
			party.forEach(army => {
				army.units.forEach(unit => {
					unit.results.fortifications = {
						initial: unit.results.current.initial,
						gained: 0,
						lost: 0
					}
				})
			})
		}
	}

	fight() {
		const attackers = this.armies.attacker.filter(army => army.fighting)
		const defenders = this.armies.defender.filter(army => army.fighting)

		this.battleResults.attacker = attackers;
		this.battleResults.defender = defenders;

		// reset
		this.battleResults.stages.battle_round = [];
		this.battleEnded = false;
		this.allArmies.forEach(army => {
			army.enemyFortifications = false
			army.initFight()
		});
		this.battleResults.towers = []


		// TERROR ROUND
		this.battleResults.stages.terror.active = this.allArmies.some(army => army.hasUnits && army.isScared());
		this.allArmies.forEach(army => army.flee())


		// FORTIFICATIONS ROUND
		let attackerFortificationKills = this.getFortificationKillsPerSide(attackers);
		let defenderFortificationKills = this.getFortificationKillsPerSide(defenders)
			+ this.getFortificationKillsFromDefBuildings();

		this.partyFortificationKills(defenders, attackerFortificationKills)
		this.partyFortificationKills(attackers, defenderFortificationKills)
		// --- FORTIFICATIONS ROUND


		//	BATTLE ROUNDS
		let currentRound = 0

		do {
			currentRound++
			console.log('ROUND', currentRound)
			const round = currentRound
			this.battleResults.stages.battle_round.push({active: true});

			const attackersDmgReduction = sum(attackers.map(army => army.getDamageReduction()))
			const defendersDmgReduction = sum(defenders.map(army => army.getDamageReduction()))

			const attackersTotalAttack = Math.max(0, sum(attackers.map(army => army.getTotalAttack(round))) - defendersDmgReduction)
			const defendersTotalAttack = Math.max(0, sum(defenders.map(army => army.getTotalAttack(round))) - attackersDmgReduction + this.getTowersDmg(round))

			const attackersPreyDmg = sumArrays(attackers.map(army => army.getPredatorAttack()))
			const defendersPreyDmg = sumArrays(defenders.map(army => army.getPredatorAttack()))

			const attackersDefeated = this.partyBattle(attackers, defendersTotalAttack, defendersPreyDmg)
			const defendersDefeated = this.partyBattle(defenders, attackersTotalAttack, attackersPreyDmg)

			if (attackersDefeated) {
				this.endBattle(CONSTANTS.ARMY.SIDE.DEFENDER)
			} else if (defendersDefeated) {
				this.endBattle(CONSTANTS.ARMY.SIDE.ATTACKER)
			} else {
				const attackersRetreated = attackers.map(army => army.shouldRetreat()).every(x => x)
				const defendersRetreated = defenders.map(army => army.shouldRetreat()).every(x => x)

				if (attackersRetreated) {
					this.endBattle(CONSTANTS.ARMY.SIDE.DEFENDER)
				} else if (defendersRetreated) {
					this.endBattle(CONSTANTS.ARMY.SIDE.ATTACKER)
				}
			}
		} while (currentRound < 20 && !this.battleEnded)

		if (currentRound === 20 && !this.battleEnded) {
			this.endBattle(CONSTANTS.ARMY.SIDE.DEFENDER)
		}
		//-------- BATTLE ROUNDS


		// HEAL ROUND
		this.battleResults.stages.heal.active = true;
		this.allArmies.forEach(army => army.healRound())
		// todo: share left heals with allies
		//-------- HEAL ROUND


		// REJOIN ROUND
		this.battleResults.stages.rejoin.active = this.battleResults.stages.terror.active
		if (this.battleResults.stages.terror.active) {
			this.allArmies.forEach(army => army.rejoin())
		}


		//NECROMANCY ROUND
		// console.log('get all dead from all armies')
		// const deadAttackers = attackers.map(army => army.getDeadUnits())
		// const deadDefenders = defenders.map(army => army.getDeadUnits())
		//
		// attackers.forEach((army, index) => {
		// 	const self = deadAttackers[index]
		// 	const allies = deadAttackers.filter((n, i) => i !== index)
		// 	const enemies = deadDefenders.map((n, i) => {
		// 		return {count: n, race: defenders[i].race}
		// 	})
		// 	army.necromancy({self, allies, enemies})
		// })
		//
		// defenders.forEach((army, index) => {
		// 	const self = deadDefenders[index]
		// 	const allies = deadDefenders.filter((n, i) => i !== index)
		// 	const enemies = deadAttackers.map((n, i) => {
		// 		return {count: n, race: attackers[i].race}
		// 	})
		// 	army.necromancy({self, allies, enemies})
		// })

		// console.log('battle finished', toJS(this))

	}

	endBattle(winner) {
		this.battleEnded = true
		this.winner = winner;
	}

	getFortificationKillsFromDefBuildings() {
		let fortKills = 0
		const defSpecLvl = this.battlefieldSetup.defenderSpecLvl
		const armyType = isPlayer(this.armies.defender[0].race) ? 'playerBuildings' : 'monsterBuildings'
		const fortPropKills = UNIT_PROPS[armyType].fortification.kills

		this.battlefieldSetup.fortifications
			.filter(b => b.type === CONSTANTS.DEF_BUILDINGS.FORTIFICATION)
			.forEach(building => {
				fortKills += (fortPropKills[building.lvl - 1] + 10 * defSpecLvl) * building.count
			})

		let fortProtection = (100 + this.armies.defender[0].fortifications_effectiveness) / 100
		fortProtection = Math.max(0, fortProtection)

		return fortKills * fortProtection
	}

	getFortificationKillsPerSide(armySide) {
		let initial = 0
		armySide.forEach(army => {
			const fortKillsArray = army.receivedEffects
				.filter(effect => effect.type === CONSTANTS.EFFECTS.FORTIFICATION_KILLS).map(e => +e.amount)
			initial += fortKillsArray.length > 0
				? fortKillsArray.reduce((total = 0, e = 0) => total + e) : 0
		})
		return initial
	}

	updateSavedArmiesList() {
		this.allArmies.forEach(army => army.updateSavedArmies())
	}

}

const calculatorStore = new CalculatorStore();

export default calculatorStore;
