import CONSTANTS from "./CONSTANTS";

const NewEffectProps = {
    type: null,
    amount: 0,
    parties: {
        self: true,
        ally: true,
        enemy: true
    },
    units: { },
    race: {
        human: true,
        elf: true,
        demon: true,
        drow: true,
        undead: true,
        monster: true
    },
    terrain: {},
    domain: {
        any: true,
        saltLake: false,
        clanCastle: false
    },
    enemy: {
        any: true,
        monster: false,
        enemyFaction: false,
        sameFaction: false,
        sameRace: false
    },
    mission: {
        any: true,
        attack: false,
        defense: false
    },
    defender: {
        any: true,
        renegade: false,
        nonRenegade: false
    },
    self: {
        any: true,
        renegade: false,
        nonRenegade: false
    }
}

for (const val of Object.values(CONSTANTS.ARMY.UNITS)) {
    NewEffectProps.units[val] = true
}

for (const val of Object.values(CONSTANTS.TERRAIN)) {
    NewEffectProps.terrain[val] = true
}

export {NewEffectProps};