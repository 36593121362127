export const CUSTOM_ATTACK = {
	attacker: [
		[
			[0, 12, 0, 16, 0, 2, 0, 0],
			[0, 10, 0, 14, 0, 1, 0, 0],
			[0, 10, 0, 16, 0, 2, 0, 0],
			[0, 10, 0, 15, 0, 1, 0, 0],
			[0, 11, 0, 14, 0, 1, 0, 0],
			[0, 10, 0, 16, 0, 1, 0, 0],
			[0, 10, 0, 16, 0, 1, 0, 0],
			[0, 11, 0, 14, 0, 1, 0, 0],
			[0, 10, 0, 14, 0, 1, 0, 0],
			[0, 11, 0, 14, 0, 2, 0, 0],
			[0, 12, 0, 14, 0, 2, 0, 0],
			[0, 10, 0, 16, 0, 2, 0, 0],
			[0, 11, 0, 16, 0, 2, 0, 0],
			[0, 12, 0, 15, 0, 1, 0, 0],
			[0, 11, 0, 15, 0, 2, 0, 0],
			[0, 11, 0, 14, 0, 2, 0, 0],
			[0, 10, 0, 16, 0, 2, 0, 0],
			[0, 11, 0, 16, 0, 2, 0, 0],
			[0, 10, 0, 14, 0, 2, 0, 0],
			[0, 10, 0, 15, 0, 1, 0, 0],
			[0, 0, 0, 0, 0, 0, 0, 0]
		],
		[
			[0, 0, 0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0, 0, 0],
		],
		[
			[0, 0, 0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0, 0, 0],
		]
	],
	defender: [
		[
			[0, 8, 10, 12, 47, 2, 9, 5],
			[0, 7, 8, 11, 49, 2, 9, 5],
			[0, 6, 9, 12, 54, 1, 7, 5],
			[0, 8, 8, 10, 0, 2, 8, 5],
			[0, 6, 8, 10, 0, 2, 7, 5],
			[0, 7, 8, 12, 0, 2, 7, 5],
			[0, 6, 9, 10, 0, 1, 8, 5],
			[0, 6, 10, 11, 0, 1, 9, 5],
			[0, 8, 8, 11, 0, 2, 7, 5],
			[0, 8, 8, 11, 0, 1, 9, 5],
			[0, 7, 0, 11, 0, 1, 0, 5],
			[0, 6, 0, 10, 0, 1, 0, 5],
			[0, 7, 0, 12, 0, 1, 0, 5],
			[0, 7, 0, 12, 0, 2, 0, 5],
			[0, 6, 0, 10, 0, 2, 0, 5],
			[0, 7, 0, 12, 0, 1, 0, 5],
			[0, 6, 0, 12, 0, 2, 0, 5],
			[0, 6, 0, 12, 0, 1, 0, 5],
			[0, 8, 0, 10, 0, 2, 0, 5],
			[0, 8, 0, 10, 0, 1, 0, 5],
			[0, 0, 0, 0, 0, 0, 0, 0]
		],
		[
			[0, 6, 10, 10, 45, 1, 8, 5],
			[0, 7, 9, 11, 45, 2, 7, 5],
			[0, 8, 9, 11, 45, 1, 8, 5],
			[0, 7, 10, 12, 0, 2, 7, 5],
			[0, 6, 9, 12, 0, 1, 9, 5],
			[0, 8, 8, 12, 0, 1, 7, 5],
			[0, 8, 9, 12, 0, 1, 9, 5],
			[0, 8, 9, 10, 0, 1, 8, 5],
			[0, 6, 8, 11, 0, 1, 8, 5],
			[0, 8, 8, 10, 0, 2, 9, 5],
			[0, 7, 0, 11, 0, 2, 0, 5],
			[0, 8, 0, 10, 0, 1, 0, 5],
			[0, 8, 0, 11, 0, 1, 0, 5],
			[0, 8, 0, 11, 0, 1, 0, 5],
			[0, 6, 0, 10, 0, 1, 0, 5],
			[0, 7, 0, 10, 0, 1, 0, 5],
			[0, 7, 0, 12, 0, 1, 0, 5],
			[0, 7, 0, 10, 0, 1, 0, 5],
			[0, 6, 0, 11, 0, 2, 0, 5],
			[0, 8, 0, 10, 0, 2, 0, 5],
			[0, 0, 0, 0, 0, 0, 0, 0]
		],
		[
			[0, 8, 10, 10, 52, 2, 9, 5],
			[0, 8, 10, 10, 51, 2, 9, 5],
			[0, 8, 9, 12, 55, 1, 9, 5],
			[0, 7, 9, 10, 0, 1, 8, 5],
			[0, 8, 8, 10, 0, 1, 7, 5],
			[0, 8, 10, 10, 0, 1, 9, 5],
			[0, 8, 9, 12, 0, 1, 7, 5],
			[0, 7, 9, 12, 0, 1, 9, 5],
			[0, 6, 8, 10, 0, 2, 9, 5],
			[0, 6, 9, 10, 0, 2, 9, 5],
			[0, 6, 0, 10, 0, 1, 0, 5],
			[0, 7, 0, 10, 0, 1, 0, 5],
			[0, 8, 0, 10, 0, 2, 0, 5],
			[0, 8, 0, 11, 0, 1, 0, 5],
			[0, 8, 0, 12, 0, 2, 0, 5],
			[0, 6, 0, 10, 0, 2, 0, 5],
			[0, 6, 0, 12, 0, 2, 0, 5],
			[0, 6, 0, 11, 0, 2, 0, 5],
			[0, 6, 0, 11, 0, 2, 0, 5],
			[0, 6, 0, 10, 0, 2, 0, 5],
			[0, 0, 0, 0, 0, 0, 0, 0]
		],
		[
			[0, 0, 0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0, 0, 0],
		]

	]
}
