import React from "react";
import PayPalButton from "./PayPalButton";

const Announcement = () => {
	const dt = new Date(1680703393475)

	return (
		<div className="dialog calculator_border announcement">
			<div>
				<p className='welcome_title'>Welcome to online MyLands calculator.</p>
				<p>Last update: <span className='text-info'>{dt.toDateString()}</span></p>
				<h3>Ancient and Perfect artifacts added</h3>
				<p>Check them out!!!</p>

				<p>Please support the development by donating for a coffee</p>
				<PayPalButton/>
				<p>This is still a work in progress. New features are being added regularly</p>
				<p>However you can manually add all the effects from hero skills, scrolls, and artifacts in the
					effects tab.</p>
				<p>The calculator should be fairly easy to use, as it has a similar design to the in game one.
					Soon some instructions will be added.</p>
				<p>Language support for other languages will be added later. В первую очередь будет
					добавлен <span className='fw-bold'>русский язык</span>. Жмите на кнопку выше чтобы
					ускорить этот процесс.</p>
				<p>You have a complaint? Leave me a message in the last step on the donation page.</p>
				<PayPalButton/>

				<p>Main tasks on the list</p>
				<ul>
					<li>Add artifact customization page</li>
					<li>Add internationalization (languages)</li>
					<li>Add clan banners</li>
					<li>Add battle scrolls</li>
				</ul>
			</div>
		</div>
	)
}

export default Announcement
