export const BATTLE_STAGES = {
	CURRENT: 'current',
	TOTALS: 'totals',
	SUMMARY: 'summary',
	TERROR: 'terror',
	FORTIFICATIONS: 'fortifications',
	BATTLE_ROUND: 'battle_round',
	HEAL: 'heal',
	REJOIN: 'rejoin',
	NECROMANCY: 'necromancy'
}