// Libs
import {v4 as uuidv4} from 'uuid';
import {Button, Form, InputGroup} from "react-bootstrap";
import {observer} from "mobx-react";

// Custom
import ArtifactSlot from "./ArtifactSlot";
import artifactStore from "../../../stores/ArtifactStore";


const ArtifactsTab = ({hero}) => {
	const displayPagination = artifactStore.displayPagination ? 'block' : 'none'

	return (
		<div className="hero_artifacts_tab">
			<div className="artifact_filter">
				<InputGroup size="sm">
					<Form.Select defaultValue={artifactStore.levelFilterOption} className='lvl-select'
					             ref={artifactStore.levelSelect}
					             onChange={(evt) => artifactStore.filterArtifacts(evt.target.value, "lvl")}>
						<option value="default">All Levels</option>
						{[1, 2, 3, 4, 5].map(x => <option key={x} value={x}>{x}</option>)}
					</Form.Select>

					<Form.Select defaultValue={artifactStore.setFilterOption} className='set-select'
					             ref={artifactStore.setNameSelect}
					             onChange={(evt) => artifactStore.filterArtifacts(evt.target.value, "set")}>
						<option value="default">All Sets</option>
						{artifactStore.SetsList.map((setName, i) => <option value={setName} key={i}>{setName}</option>)}
					</Form.Select>

					<Form.Select defaultValue={artifactStore.typeFilterOption} className='position-select'
					             ref={artifactStore.typeSelect}
					             onChange={(evt) => artifactStore.filterArtifacts(evt.target.value, "place")}>
						<option value="default">All types</option>
						{artifactStore.PositionsList.map((position, i) => <option value={position} key={i}>{position}</option>)}
					</Form.Select>
				</InputGroup>
			</div>

			<div className="artifacts_container">
				{artifactStore.DisplayedArtifacts.map(artifact => {
					const k = uuidv4();
					return (<div className="artifact_slot" key={k}>
						<ArtifactSlot artifact={artifact} clickMe={e => hero.putArtifactOn(e)}/>
					</div>)
				})}
			</div>

			<div className="pagination" style={{display: displayPagination}}>
				<InputGroup size="sm" className="pagination-buttons">
					<Button variant="outline-info" onClick={() => artifactStore.flipPage(-1)}
					        disabled={artifactStore.currentPage <= 1}/>
					<div className="page-num">Page {artifactStore.currentPage} of {artifactStore.totalPages}</div>
					<Button variant="outline-info" onClick={() => artifactStore.flipPage(1)}
					        disabled={artifactStore.currentPage >= artifactStore.totalPages}/>
				</InputGroup>
			</div>

			<div className="equip_set"
			     style={{display: artifactStore.filterBySet && !artifactStore.filterByPosition ? 'block' : 'none'}}>
				<Button variant="primary"
				        // disabled={true}
				        onClick={()=>hero.putSetOn(artifactStore.DisplayedArtifacts)}>
					Equip Set
				</Button>
			</div>
		</div>
	)
}

export default observer(ArtifactsTab);