import CONSTANTS from "./CONSTANTS";
import {newArmyUnits} from "../utilities";

const UNIT_PROPS = {
    carrier: {
        atk: ['0', '0', '0', '0'],
        hp: [8, 12, 15, 20],
        rating: [0, 0, 0, 0],
        salary: [0, 0, 0, 0],
        cost: [{}, {}, {}, {}],
        EXTRA: {
            carry: [50, 50, 50, 50],
        }
    },
    warrior: {
        atk: ['2-3', '4-6', '6-8', '10-12'],
        hp: [10, 15, 25, 30],
		rating: [0, 0, 0, 0],
		salary: [0, 0, 0, 0],
		cost: [{}, {}, {}, {}]
    },
    rider: {
        atk: ['3-4', '5-7', '8-10', '10-12'],
        hp: [15, 25, 35, 40],
		rating: [0, 0, 0, 0],
		salary: [0, 0, 0, 0],
		cost: [{}, {}, {}, {}]
    },
    flying: {
        atk: ['4-6', '8-10', '10-12', '14-16'],
        hp: [20, 30, 40, 60],
		rating: [0, 0, 0, 0],
		salary: [0, 0, 0, 0],
		cost: [{}, {}, {}, {}]
    },
    ranged: {
        atk: ['25-35', '35-45', '45-55', '50-60'],
        hp: [6, 10, 15, 15],
		rating: [0, 0, 0, 0],
		salary: [0, 0, 0, 0],
		cost: [{}, {}, {}, {}]
    },
    healer: {
        atk: ['0-1', '1', '1-2', '1-2'],
        hp: [10, 15, 20, 20],
		rating: [0, 0, 0, 0],
		salary: [0, 0, 0, 0],
		cost: [{}, {}, {}, {}],
        EXTRA: {
            revive: [1, 2, 3, 4]
        }
    },
    mercenary: {
        atk: ['3-5', '5-7', '7-9', '8-10'],
        hp: [1, 1, 1, 1],
		rating: [0, 0, 0, 0],
		salary: [0, 0, 0, 0],
		cost: [{}, {}, {}, {}]
    },
    mage: {
        atk: ['3', '4', '5', '8'],
        hp: [5, 10, 15, 20],
		rating: [0, 0, 0, 0],
		salary: [0, 0, 0, 0],
		cost: [{}, {}, {}, {}],
        EXTRA: {
            reduceTowerDmg: [10, 20, 30, 40]
        }
    },
    playerBuildings: {
        tower: ['600-700', '1100-1200', '2000-2500', '5000-7000', '10000-12000', '15000-18000', '20000-25000', '50000-60000'],
        magic_tower: [.5, .7, .9, 1.1, 1.3, 1.5, 1.8, 2.2], //todo: on some servers for 7th lvl the multiplier is 1.7 instead of 1.8
        fortification: {
            kills: [30, 60, 90, 120, 150, 180, 210, 300],
            extra_defence: [2, 3, 4, 5, 6, 7, 8, 10]
        }
    },
    monsterBuildings: {
        tower: ['3600-4200', '6600-7200', '12000-15000', '30000-42000', '60000-72000', '90000-108000', '120000-150000'],
        magic_tower: [1, 1.4, 1.8, 2.2, 2.6, 3, 3.6],
        fortification: {
            kills: [300, 900, 1200, 1500, 1800, 2400, 3000],
            extra_defense: [3, 4, 5, 6, 7, 11, 15]
        },
        gate: {
            dmgReduction: [100000, 200000, 300000, 400000, 500000, 650000, 800000],
            maxHp: [2000000, 3000000, 4000000, 5000000, 6000000, 8000000, 10000000]
        }
    },

    get elf() {
        const elf = newArmyUnits(this)
        elf.healer.EXTRA.revive = [2, 3, 4, 5]
        elf.fraction = CONSTANTS.ARMY.FRACTION.LIGHT;
        return elf
    },

    get human() {
        const human = newArmyUnits(this)
        human.carrier.EXTRA.carry = [100, 100, 100, 100]
        human.fraction = CONSTANTS.ARMY.FRACTION.LIGHT;
        return human
    },

    get demon() {
        const demon = newArmyUnits(this)
        demon.mercenary.atk = ['5-7', '8-10', '11-13', '12-14']
        demon.fraction = CONSTANTS.ARMY.FRACTION.DARK;
        return demon
    },

    get drow() {
        const drow = newArmyUnits(this)
        drow.mage.EXTRA.reduceTowerDmg = [20, 40, 60, 80]
        drow.fraction = CONSTANTS.ARMY.FRACTION.DARK;
        return drow
    },

    get undead() {
        const undead = newArmyUnits(this)
        undead.fraction = CONSTANTS.ARMY.FRACTION.DARK;
        return undead
    },

    get monster() {
        const monster = newArmyUnits(this)
        monster.fraction = CONSTANTS.ARMY.FRACTION.NEUTRAL;
        return monster
    },

    get clan_castle() {
        const clan_castle = newArmyUnits(this)
        clan_castle.fraction = CONSTANTS.ARMY.FRACTION.NEUTRAL;
        return clan_castle
    }
}

export default UNIT_PROPS;