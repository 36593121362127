// Libs
import {observer} from "mobx-react";

// Custom
import Fortification from "./Fortification";
import CONSTANTS from "../../data/CONSTANTS";
import calculatorStore from "../../stores/CalculatorStore";
import {isPlayer, unSnake} from "../../utilities";

const BattlefieldSetup = () => {
    const battlefieldSetup = calculatorStore.battlefieldSetup;

    const dmg = battlefieldSetup.towerDamage.map(t=>t.dmg)
    const {maxDefence, defenderSpecLvl, saltLake, terrain, newFortification, fortifications} = battlefieldSetup

    return (
        <div className="battlefield_setup">
            <div className="spec_defender">
                <div className="spec_level">
                    {[0, 1, 2, 3].map((lvl, index) => (
                        <button key={index} className={lvl === defenderSpecLvl ? 'selected' : ''}
                                onClick={() => calculatorStore.updateDefSpecLvl(lvl)}>
                            {lvl}
                        </button>
                    ))}
                </div>
            </div>

            <div className="right">
                <div className="row top">
                    <div className="col-sm-4">
                        <select className="form-control custom-select"
                                defaultValue={terrain}
                                onChange={e => calculatorStore.changeTerrain(e.target.value)}>
                            {Object.values(CONSTANTS.TERRAIN)
                                .map((terrain, index) => (
                                    <option key={index}
                                            value={terrain}>
                                        {unSnake(terrain)}
                                    </option>)
                                )}
                        </select>
                    </div>

                    <div className="max_def">
                        <input type="checkbox" id="max_def_input"
                               defaultValue={maxDefence}
                               checked={maxDefence}
                               onChange={() => calculatorStore.toggleMaxDefence()}/>
                        <label htmlFor="max_def_input">max defence</label>
                    </div>

                    <div className="salt_lake">
                        <input type="checkbox" id="salt_lake_input"
                               defaultValue={saltLake}
                               checked={saltLake}
                               onChange={() => calculatorStore.toggleSaltLake()}/>
                        <label htmlFor="salt_lake_input">Salt Lake</label>
                    </div>
                </div>
                <div className="def_buildings">

                    <div className="fortification_select">
                        <div className="forts">
                            {Object.values(CONSTANTS.DEF_BUILDINGS).map((building, index) => {
                                const selected = building === newFortification.type ? 'selected' : '';
                                const isTower = [CONSTANTS.DEF_BUILDINGS.TOWER, CONSTANTS.DEF_BUILDINGS.MAGIC_TOWER].includes(building)
                                const isGate = building === CONSTANTS.DEF_BUILDINGS.GATES
                                const showGate = (isGate && isPlayer(calculatorStore.armies.defender[0].race)) ? 'd-none' : 'd-block'
                                const disabled = ((isTower && newFortification.noMoreTowers) || (isGate && newFortification.noMoreGates))
                                    ? 'disabled' : '';
                                return (
                                    <button key={index}
                                            className={`${building} ${selected} ${disabled} ${showGate}`}
                                            disabled={!!disabled}
                                            onClick={() => calculatorStore.updateNewFort(building)}/>
                                )
                            })}

                            <button className="add_building"
                                    onClick={() => calculatorStore.addDefBuilding()}/>
                        </div>

                        <div className="spec_level">
                            {[1, 2, 3, 4, 5, 6, 7, 8].map((lvl, index) => (
                                <button key={index}
                                        className={lvl === newFortification.lvl ? 'selected' : ''}
                                        onClick={() => calculatorStore.updateNewFortLvl(lvl)}>
                                    {lvl}
                                </button>
                            ))}
                        </div>
                    </div>

                    <div className="buildings">
                        {fortifications.map((building, index) => {
                            return (<Fortification key={index}
                                                   {...building}
                                                   dmg={dmg[index]}
                                                   onRemove={(building) => calculatorStore.removeDefBuilding(building)}/>)
                        })}
                    </div>

                </div>
            </div>

        </div>
    )
}

export default observer(BattlefieldSetup);