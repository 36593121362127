// Libs
import {makeAutoObservable, toJS} from "mobx";
import {createRef} from "react";

// Custom

// data
import art_assets from '../data/artifact_assets.json'
import {decodeEffect, deepClone, getPerfectArtifactEffects} from "../utilities";

const {ArtifactAssets, PositionsList} = art_assets

class ArtifactStore {
	// todo: load saved artifacts from BE
	// SavedArtifacts = [{
	// 	isAncient: false,
	// 	isUpgrade: true,
	// 	lvl: "1",
	// 	name: "elven",
	// 	place: "back",
	// 	position: "1287:1064:60:60",
	// 	type: "artifact",
	// 	runes: "356"
	// }]

	SavedArtifacts = []

	DefaultArtifactsList = ArtifactAssets.filter(x => x.type === 'artifact').map(x => ({
		...x,
		isUpgraded: false,
		isAncient: false,
		workingEffects: deepClone(x.effects),
		perfectEffects: getPerfectArtifactEffects(x.place, x.lvl)
	}))
	FilteredArtifacts = [...this.SavedArtifacts, ...this.DefaultArtifactsList]
	DisplayedArtifacts = [...this.FilteredArtifacts]
	SetsList = [...new Set(this.DefaultArtifactsList.map(x => x.setName))]
	PositionsList = PositionsList
	filterByLvl = false
	filterByPosition = false
	filterBySet = false
	setNameSelect = createRef()
	levelSelect = createRef()
	typeSelect = createRef()
	displayPagination = false
	currentPage = 1
	totalPages = 1
	levelFilterOption = 'default'
	setFilterOption = 'default'
	typeFilterOption = 'default'
	selectedArtifact = {
		isAncient: false,
		isUpgraded: false
	}

	constructor() {
		makeAutoObservable(this)
		this.arrangePagination()

	}

	arrangePagination() {
		const filtered = this.FilteredArtifacts
		if (filtered.length > 54) {
			this.totalPages = Math.ceil(filtered.length / 48)
			this.displayPagination = true
			this.DisplayedArtifacts = filtered.slice((this.currentPage - 1) * 48, this.currentPage * 48)
		} else {
			this.displayPagination = false
			this.DisplayedArtifacts = filtered
		}
	}

	toggleAncient(val) {
		this.selectedArtifact.isAncient = val
		const {place} = this.selectedArtifact
		this.selectedArtifact.hero.updateArtifactEffects(place, val)
	}

	togglePerfect(val) {
		const {perfectEffects, hero} = this.selectedArtifact
		if (Array.isArray(perfectEffects.effects)) {
			if (val) {
				perfectEffects.effects.forEach(e => hero.effects.addEffect(decodeEffect(e)))
			} else {
				perfectEffects.effects.forEach(e => hero.findAndRemoveEffect(e))
			}
		}
		this.selectedArtifact.isUpgraded = val
	}

	flipPage(n) {
		this.currentPage += n
		this.arrangePagination()
	}

	updateFilter(category, value = "default") {
		switch (category) {
			case 'lvl':
				this.levelFilterOption = value
				this.levelSelect.current.value = value
				break
			case 'set':
				this.setFilterOption = value
				this.setNameSelect.current.value = value
				break
			case 'place':
				this.typeFilterOption = value
				this.typeSelect.current.value = value
				break
			default:
		}
	}

	filterByType(type) {
		this.filterBySet = false
		this.filterByLvl = false
		this.filterArtifacts(type, 'place')
	}

	filterArtifacts(value, filterBy) {
		this.updateFilter(filterBy, value)
		switch (filterBy) {
			case 'lvl':
				this.filterByLvl = [1, 2, 3, 4, 5].includes(+value) ? value : false

				if (this.filterByLvl) {
					this.SetsList = [...new Set(this.DefaultArtifactsList
						.filter(x => x.lvl === this.filterByLvl).map(x => x.setName))]
				} else {
					this.SetsList = [...new Set(this.DefaultArtifactsList.map(x => x.setName))]
				}

				this.filterBySet = false
				this.setNameSelect.current.value = "default";
				this.currentPage = 1
				break

			case 'place':
				this.typeFilterOption = value
				this.filterByPosition = this.PositionsList.includes(value) ? value : false
				break

			case 'set':
				this.filterBySet = this.SetsList.includes(value) ? value : false
				break

			default:
				console.log('can\'t find this filter')
		}

		let filtered = [...this.SavedArtifacts, ...this.DefaultArtifactsList]
		if (this.filterByLvl) {
			filtered = filtered.filter(x => x.lvl === this.filterByLvl)
		}
		if (this.filterByPosition) {
			filtered = filtered.filter(x => x.place === this.filterByPosition)
		}
		if (this.filterBySet) {
			filtered = filtered.filter(x => x.setName === this.filterBySet)
		}

		this.FilteredArtifacts = filtered
		this.arrangePagination()
	}

	findById(id) {
		return ArtifactAssets.find(x => x.uid === id)
	}

}

const artifactStore = new ArtifactStore();
export default artifactStore;