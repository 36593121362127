import {Form} from "react-bootstrap";
import {capitalize, unCamel, unSnake} from "../../../utilities";
import {observer} from "mobx-react";

const EffectCondition = ({effects, condition, type}) => {
    const allChoices = !Object.values(effects.newEffect[condition]).includes(false)
    const isCheckbox = type === "checkbox"
    const condDef = condition === 'defender'

    const toggle = (key) => {
        isCheckbox ? effects.toggleCondition(condition, key) : effects.toggleConditionRadio(condition, key)
    }

    const fixLabel = (label) => {
        switch (condition) {
            case 'terrain':
                return unSnake(label)
            case 'domain':
            case 'enemy':
                return unCamel(label)
            default:
                return capitalize(label)
        }
    }

    return <fieldset className={`cond_${condition}`} disabled={effects.disableInputs[condition]}>
        <Form>
            <Form.Label className='form-title'>{capitalize(condition)}</Form.Label>
            {isCheckbox && !condDef && (
                <Form.Check type={"checkbox"} label="All"
                            defaultValue={allChoices}
                            id={`effect_condition_${condition}`}
                            checked={allChoices}
                            onChange={() => effects.toggleConditionAll(condition, !allChoices)}/>)}

            {Object.keys(effects.newEffect[condition])
                .map(key => <Form.Check key={key}
                                        type={type}
                                        label={fixLabel(key)}
                                        id={`effect_condition_${condition}_${key}`}
                                        name="group1"
                                        checked={effects.newEffect[condition][key]}
                                        onChange={() => toggle(key)}
                                        defaultValue={effects.newEffect[condition][key]}/>
                )}
        </Form>
    </fieldset>
}

export default observer(EffectCondition);