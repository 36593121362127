export const EffectsLists = {
	host: [
		"terror.90.1.255",
		"attack.-50.1.255",
		"defence.-25.1.255",
		"improved_healing.-50.1.255",
		"defence.25.6.255",
		"mages_dmg_reduction.40.4.255",
		"hp.45.4.1",
		"attack.90.4.1",
		"attack.-160.1.1"
	],
	defender: [
		"terror.90.1.255",
		"attack.-50.1.255",
		"defence.-25.1.255",
		"attack.25.6.255",
		"defence.25.6.255",
		"mages_dmg_reduction.-30.1.255",
		"attack.-120.1.1",
		"hp.-60.1.1"
	],
	snow: [
		"terror.50.1.255",
		"mages_dmg_reduction.50.4.255",
		"attack.-24.1.255",
		"attack.-18.1.255",
		"attack.-12.1.255",
		"hp.40.4.255",
		"hp.55.4.255",
		"max_defence.-24.1.255",
		"attack.9.4.255",
		"defence.36.4.255",
		"max_defence.12.4.255",
		"attack.40.4.255",
		"attack.49.4.255",
		"attack.-6.1.255",
		"defence.100.4.255",
		"defence.3.4.255",
		"hp.30.4.255",
		"attack.-25.1.255",
		"attack.-9.1.255",
		"attack.9.4.255",
		"attack.-25.7.255",
		"attack.-25.1.255",
		"hp.15.4.16"
	]
}
