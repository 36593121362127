import {observer} from "mobx-react";
import ArtifactSlot from "../Artifacts/ArtifactSlot";
import art_assets from '../../../data/artifact_assets.json'

const {PositionsList} = art_assets

const HeroDoll = ({hero}) => {
	return (
		<div className={'hero_doll hero_doll_' + hero.gender}>
			{PositionsList.map(position => {
				return (<div key={position} className={`hero_artifact ${position}`}>
					<ArtifactSlot artifact={hero.artifacts[position]}
					              clickMe={() => hero.clickArtifactSlot(position)}
					              remove={() => hero.takeArtifactOff(position)}/>
				</div>)
			})}
		</div>
	)
}

export default observer(HeroDoll);