// Libs
import {observer} from "mobx-react";

// Custom Components
import Skill from "./Skill";
import SkillBranchButton from "./SkillBranchButton";


const HeroSkills = ({hero}) => {
	return hero.heroClass && (
		<div className="hero_skills">
			<div className="class_skills">
				{hero.baseSkills.map((sk, i) => <Skill key={i} props={sk}/>)}
			</div>
			<div className="specializations">
				<div className="hero_specialization">
					<div className="specialization_name">{hero.specialization?.toLowerCase() || ''}</div>
					<div className="skills">
						{hero.specializationSkills.map((sk, i) => <Skill key={i} props={sk}/>)}
					</div>
				</div>
				<div className="hero_specialization">
					<div className="specialization_name"><SkillBranchButton id={0} name={hero.extraSkills[0].name} hero={hero}/></div>
					<div className="skills">
						{hero.extraSkills[0].skills.map((sk, i) => <Skill key={i} props={sk}/>)}
					</div>
				</div>
				<div className="hero_specialization">
					<div className="specialization_name"><SkillBranchButton id={1} name={hero.extraSkills[1].name} hero={hero}/></div>
					<div className="skills">
						{hero.extraSkills[1].skills.map((sk, i) => <Skill key={i} props={sk}/>)}
					</div>
				</div>
				<div className="hero_specialization advanced_skills">
					<div className="skills">
						{hero.advancedSkills && hero.advancedSkills.map((sk, i) => <Skill key={i} props={sk}/>)}
					</div>
				</div>
			</div>

		</div>
	)
}

export default observer(HeroSkills);