// Utils
import {decodeEffect, descriptionToHtml, getAncientDescription} from "../../../utilities";
import EffectLabel from "../Effects/EffectLabel";


const ArtifactInfo = (props) => {
	const {
		description,
		workingEffects,
		perfectEffects,
		isUpgraded: isPerfect,
		isAncient,
		longName,
		place,
		bothHands,
		showDescriptionToo
	} = props

	const bodyPartMap = {
		"back": "back",
		"wear": "chest",
		"ring": "fingers",
		"helmet": "head",
		"item": "backpack",
		"weapon": "right hand",
		"footwear": "legs",
		"necklace": "neck",
		"shield": "left hand",
		"thigh": "hips",
		"belt": "belt",
		"wrist": "wrist"
	}

	const equip = bothHands ? "both hands" : bodyPartMap[place]

	const getPerfect = () => {
		if (isPerfect) {
			return <>
				<p className="effect perfect title">Effects of Perfect Artifact:</p>
				{perfectEffects.description ? perfectEffects.description.split('\n')
					.map((d, i) => <p className="effect perfect opacity-75" key={i}>{descriptionToHtml(d)}</p>) : ''}

				{Array.isArray(perfectEffects.effects) ?
					perfectEffects.effects.map((e, i) => <EffectLabel key={i} extraClass='perfect'
					                                                  effect={decodeEffect(e)}/>) : ''}
			</>
		}
	}

	const getBodyPart = () => {
		return <p className="effect title">Can be equipped on {equip}</p>
	}

	if (!workingEffects) {
		return (
			<div className="unit_info">
				{longName ? <p className="effect fw-bold" style={{marginBottom: '5px'}}>{longName}</p> : ''}
				{description ? description.split('\n')
					.map((d, i) => <p className="effect opacity-75"
					                  key={i}>{descriptionToHtml(isAncient ? getAncientDescription(d) : d)}</p>) : ''}
				{getPerfect()}
				{getBodyPart()}
			</div>
		)
	} else {
		return (
			<div className="unit_info">
				{longName ? <p className="effect fw-bold" style={{marginBottom: '5px'}}>{longName}</p> : ''}

				{showDescriptionToo && description ? description.split('\n')
					.map((d, i) => <p className="effect opacity-75" key={i}>{descriptionToHtml(isAncient ? getAncientDescription(d) : d)}</p>) : ''}

				{workingEffects.map((e, i) => <EffectLabel key={i} effect={decodeEffect(e)}/>)}
				{getPerfect()}
				{getBodyPart()}
			</div>
		)
	}
}

export default ArtifactInfo;