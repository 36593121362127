// Libs
import {observer} from "mobx-react";

// Data
import UNIT_PROPS from "../../data/UNIT_PROPS";

// Store
import calculatorStore from "../../stores/CalculatorStore";

// Custom Components
import BonusProp from "../Hero/Effects/BonusProp";

// Utils
import {isPlayer, unSnake} from "../../utilities";


const FortificationInfo = ({type, lvl, roundNumber, dmg}) => {
    const mainDefender = calculatorStore.armies.defender[0]
    const towerBonuses = calculatorStore.battlefieldSetup.towerBonuses
    const buildings = UNIT_PROPS.playerBuildings
    let info = <></>

    if (isPlayer(mainDefender.race)) {
        const effect = {
            get tower() {
                const bonus = towerBonuses.tower_attack
                return (
                    <p>
                        <i className="utility-icon atk"/>
                        {Math.round(dmg)}
                        <BonusProp bonus={bonus} name='TOWER_ATTACK'/>
                    </p>
                )
            },
            get magic_tower() {
                const multiplier = buildings.magic_tower[lvl - 1]
                const bonus = towerBonuses.magic_tower_attack
                return (
                    <p>
                        <i className="utility-icon atk"/>
                        {Math.round(dmg)} {isNaN(roundNumber) ? `(${multiplier} * avg dmg)` : ''}
                        <BonusProp bonus={bonus} name='TOWER_ATTACK'/>
                    </p>
                )
            },
            get fortification() {
                const fort = buildings.fortification
                return <>
                    <p>Enemy losses: {fort.kills[lvl - 1]}</p>
                    <p>Defence: +{fort.extra_defence[lvl - 1]}</p>
                </>
            }
        }
        info = effect[type]
    }

    return (
        <div className="unit_info">
            <p>{unSnake(type)} [lvl {lvl}]</p>
            {info}
        </div>
    )
}

export default observer(FortificationInfo);