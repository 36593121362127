const HEROES = {
    CLASSES: {
        COMMANDER: {
            NAME: 'COMMANDER',
            SKILLS: [
                {
                    NAME: 'hero_speed',
                    DESCRIPTION: 'Hero\'s squad mission duration {0}',
                    EFFECTS: null,
                    STATS: ['-5%', '-10%', '-15%', '-20%', '-25%']
                },
                {
                    NAME: 'trebuchet',
                    DESCRIPTION: 'Kills {0} units in the Fortifications round',
                    EFFECTS: ['fortification_kills.{0}.4'],
                    STATS: [2000, 3500, 5000, 6500, 8000]
                },
                {
                    NAME: 'mana_regeneration',
                    DESCRIPTION: 'Mana regeneration +{0} \n Maximum mana +{1}',
                    EFFECTS: null,
                    STATS: [[5, 200], [10, 400], [15, 600], [20, 800], [25, 1000]]
                }
            ],
            SPECIALIZATIONS: {
                CRAFTSMAN: {
                    SKILLS: [
                        {
                            NAME: 'bottomless_bag',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'maximum_luck',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'chasing_of_assassin',
                            DESCRIPTION: 'Attack of all pursuers +{0}% for each level of the artifact with a basic attack of pursuers effect',
                            EFFECTS: ['chasing_of_assassin.{0}'],
                            STATS: [1, 2, 3, 4, 5]
                        },
                        {
                            NAME: 'stone_armor',
                            DESCRIPTION: 'Defense of all troops +{0} for each level of the artifact with a basic defense effect',
                            EFFECTS: ['stone_armor.{0}'],
                            STATS: [1, 2, 3, 4, 5]
                        },
                        {
                            NAME: 'mammoth_health',
                            DESCRIPTION: 'Health of all troops +{0}% for each level of the artifact with a basic health effect',
                            EFFECTS: ['mammoth_health.{0}'],
                            STATS: [1, 2, 3, 4, 5]
                        },
                        {
                            NAME: 'sharp_spade',
                            DESCRIPTION: 'Attack of all troops +{0}% for each level of the artifact a basic attack effect',
                            EFFECTS: ['sharp_spade.{0}'],
                            STATS: [1, 2, 3, 4, 5]
                        },
                        {
                            NAME: 'present',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        }
                    ],
                    GENDERS: {
                        MALE: {
                            POSITION: '-924px -627px'
                        },
                        FEMALE: {
                            POSITION: '-1008px -627px'
                        }
                    }
                },
                GLADIATOR: {
                    SKILLS: [
                        {
                            NAME: 'suppression_of_resurrector',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'destroyer',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'raskol_zhizni',
                            DESCRIPTION: 'All units Health value -{0}%\n Affects Enemy',
                            EFFECTS: ["hp.-{0}.1"],
                            STATS: [5, 10, 15, 20, 25]
                        },
                        {
                            NAME: 'raskol_broni',
                            DESCRIPTION: 'All units defence value -{0}\n Affects Enemy',
                            EFFECTS: ["defence.-{0}.1"],
                            STATS: [5, 10, 15, 20, 25]
                        },
                        {
                            NAME: 'istrebitel',
                            DESCRIPTION: 'All units defence value +{0} \n In battle against Monsters',
                            EFFECTS: ["defence.{0}.4.255.63.511.4.8"],
                            STATS: [5, 10, 15, 20, 25]
                        },
                        {
                            NAME: 'podavlenie_instinktov',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'proklyate_snijenie_zaschity',
                            DESCRIPTION: 'Maximum defence limit -{0} \n Affects enemy',
                            EFFECTS: ["max_defence.-{0}.1"],
                            STATS: [4, 8, 12, 16, 20]
                        }
                    ],
                    GENDERS: {
                        MALE: {
                            POSITION: '-1428px -627px'
                        },
                        FEMALE: {
                            POSITION: '-1512px -627px'
                        }
                    }
                },
                HUNTER: {
                    SKILLS: [
                        {
                            NAME: 'jadnost',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'vtoroy_pohod',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'dvoinoi_fart',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'artefakthanter',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'odin_na_odin',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'skritnoe_napadenie',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'zapret',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        }
                    ],
                    GENDERS: {
                        MALE: {
                            POSITION: '-1092px -627px'
                        },
                        FEMALE: {
                            POSITION: '-1176px -627px'
                        }
                    }
                },
                SCIENTIST: {
                    SKILLS: [
                        {
                            NAME: 'svetliy_razum',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'hizhina',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'naselenie',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'prazdnik',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'smena_specializacii',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'nachitanniy_gorod',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'prokliatyi_alchimik',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        }
                    ],
                    GENDERS: {
                        MALE: {
                            POSITION: '-1260px -627px'
                        },
                        FEMALE: {
                            POSITION: '-1344px -627px'
                        }
                    }
                },
                TRAVELLER: {
                    SKILLS: [
                        {
                            NAME: 'uluchshenniy_compass',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'talisman_kupcov',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'universalnie_skoroxody',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'dalnie_issledovaniya',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'kol_misii',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'otkrit_ruiny',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'dlitelnost_missii',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        }
                    ],
                    GENDERS: {
                        MALE: {
                            POSITION: '-1596px -627px'
                        },
                        FEMALE: {
                            POSITION: '-1680px -627px'
                        }
                    }
                }
            }
        },
        STRATEGIST: {
            NAME: 'STRATEGIST',
            SKILLS: [
                {
                    NAME: 'hero_speed',
                    DESCRIPTION: 'Hero\'s squad mission duration -{0}%',
                    EFFECTS: null,
                    STATS: [5, 10, 15, 20, 25]
                },
                {
                    NAME: 'katapulta',
                    DESCRIPTION: 'Towers level reduction by each round',
                    EFFECTS: ['catapult.0.4'],
                    STATS: [0, 0, 0, 0, 0]
                },
                {
                    NAME: 'mana_regeneration',
                    DESCRIPTION: 'Mana regeneration +{0}\nMaximum mana +{1}',
                    EFFECTS: null,
                    STATS: [[5, 200], [10, 400], [15, 600], [20, 800], [25, 1000]]
                }
            ],
            SPECIALIZATIONS: {
                PALADIN: {
                    SKILLS: [
                        {
                            NAME: 'fortifications_protection',
                            DESCRIPTION: 'Losses at fortification -{0}%',
                            EFFECTS: ['fortifications_effectiveness.-{0}.1'],
                            STATS: [20, 40, 60, 80, 100]
                        },
                        {
                            NAME: 'neudacha_protivniku',
                            DESCRIPTION: 'Chance of minimum attack value of all units +{0}%, Affects enemy',
                            EFFECTS: ['bad_luck.{0}.1'],
                            STATS: [10, 20, 30, 40, 50]
                        },
                        {
                            NAME: 'protection',
                            DESCRIPTION: 'All units defense value +{0}',
                            EFFECTS: ['defence.{0}.4'],
                            STATS: [5, 10, 15, 20, 25]
                        },
                        {
                            NAME: 'ally_protection',
                            DESCRIPTION: 'All units defense value +{0}, Affects Ally',
                            EFFECTS: ['defence.{0}.2'],
                            STATS: [5, 10, 15, 20, 25]
                        },
                        {
                            NAME: 'podavlenie_monstrov',
                            DESCRIPTION: 'All units attack value -{0}%, Afects enemy, In battles against monsters',
                            EFFECTS: ['attack.-{0}.1.255.63.511.4.8'],
                            STATS: [10, 20, 30, 40, 50]
                        },
                        {
                            NAME: 'slabost_vseh_magov',
                            DESCRIPTION: 'Attack value mages -{0}%, Affects self, ally, enemy',
                            EFFECTS: ['attack.-{0}.7.1'],
                            STATS: [20, 40, 60, 80, 100]
                        },
                        {
                            NAME: 'slabost_protivnika',
                            DESCRIPTION: 'All units attack value -{0}%, Affects enemy',
                            EFFECTS: ['attack.-{0}.1'],
                            STATS: [5, 10, 15, 20, 25]
                        }
                    ],
                    GENDERS: {
                        MALE: {
                            POSITION: '-168px -829px'
                        },
                        FEMALE: {
                            POSITION: '-84px -829px'
                        }
                    }
                },
                PEACEMAKER: {
                    SKILLS: [
                        {
                            NAME: 'cautious_seizure',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'enemy_experience_decrease',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'monster_annihilator',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'slabost_presledovatelei',
                            DESCRIPTION: 'All pursuers attack value -{0}%, Affects self, ally, enemy',
                            EFFECTS: ['predator_damage.-{0}.7.120'],
                            STATS: [15, 30, 45, 60, 75]
                        },
                        {
                            NAME: 'vseobschaya_slabost',
                            DESCRIPTION: 'All units attack value -{0}%, Affects self, ally, enemy',
                            EFFECTS: ['attack.-{0}'],
                            STATS: [10, 20, 30, 40, 50]
                        },
                        {
                            NAME: 'vseobschaya_neudacha',
                            DESCRIPTION: 'Chance of minimum attack value of all units +{0}%, Affects self, ally, enemy',
                            EFFECTS: ['bad_luck.{0}'],
                            STATS: [20, 40, 60, 80, 100]
                        },
                        {
                            NAME: 'otkaz_slujit',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        }
                    ],
                    GENDERS: {
                        MALE: {
                            POSITION: '-336px -829px'
                        },
                        FEMALE: {
                            POSITION: '-252px -829px'
                        }
                    }
                },
                SABOTEUR: {
                    SKILLS: [
                        {
                            NAME: 'diversiya_kupcov',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'sabotaj_stroitelstva',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'diversiya_bashen',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'diversiya_dobychi',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'diversiya_issledovatelei',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'diversiya_zerkal_i_antishpionov',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'diversiya_trenirovok',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        }
                    ],
                    GENDERS: {
                        MALE: {
                            POSITION: '-504px -829px'
                        },
                        FEMALE: {
                            POSITION: '-420px -829px'
                        }
                    }
                },
                SCOUT: {
                    SKILLS: [
                        {
                            NAME: 'artefact_hunter',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'explorers_acceleration',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'distant_espionage',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'distant_observation',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'dozornyi',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'oko_issledovatelya',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'pohodnye_shpiony',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        }
                    ],
                    GENDERS: {
                        MALE: {
                            POSITION: '-672px -829px'
                        },
                        FEMALE: {
                            POSITION: '-588px -829px'
                        }
                    }

                },
                WARRIOR: {
                    SKILLS: [
                        {
                            NAME: 'army_strength',
                            DESCRIPTION: 'All units attack value +{0}%',
                            EFFECTS: ['attack.{0}.4'],
                            STATS: [5, 10, 15, 20, 25]
                        },
                        {
                            NAME: 'strength_to_ally',
                            DESCRIPTION: 'All units attack value +{0}%, Affects ally',
                            EFFECTS: ['attack.{0}.2'],
                            STATS: [5, 10, 15, 20, 25]
                        },
                        {
                            NAME: 'forgive_renegade',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'dobivat_ranenyh',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'ustoichivost_nosilschikov',
                            DESCRIPTION: 'Health Carriers +{0}%',
                            EFFECTS: ['hp.{0}.4.128'],
                            STATS: [5, 10, 15, 20, 25]
                        },
                        {
                            NAME: 'udacha',
                            DESCRIPTION: 'Chance of maximum attack of all units +{0}%',
                            EFFECTS: ['good_luck.{0}.4'],
                            STATS: [5, 10, 15, 20, 25]
                        },
                        {
                            NAME: 'chasing_of_assassin',
                            DESCRIPTION: 'All pursuers attack value +{0}%, Affects self, ally, enemy',
                            EFFECTS: ['predator_damage.{0}.7.120'],
                            STATS: [10, 15, 20, 25, 30]
                        }
                    ],
                    GENDERS: {
                        MALE: {
                            POSITION: '-1176px -829px'
                        },
                        FEMALE: {
                            POSITION: '-1092px -829px'
                        }
                    }
                }
            }
        },
        WIZARD: {
            NAME: 'WIZARD',
            SKILLS: [
                {
                    NAME: 'hero_speed',
                    DESCRIPTION: 'Hero\'s squad mission duration {0}',
                    EFFECTS: null,
                    STATS: ['-5%', '-10%', '-15%', '-20%', '-25%']
                },
                {
                    NAME: 'magi_zaschitniki',
                    DESCRIPTION: 'Each mage decreases damage by +{0} in battle',
                    EFFECTS: ['mages_dmg_reduction.{0}.4'],
                    STATS: [10, 15, 20, 25, 30]
                },
                {
                    NAME: 'mana_regeneration',
                    DESCRIPTION: 'Mana regeneration +{0}\nMaximum mana +{1}',
                    EFFECTS: null,
                    STATS: [[5, 200], [10, 400], [15, 600], [20, 800], [25, 1000]]
                }
            ],
            SPECIALIZATIONS: {
                NECROMANCER: {
                    SKILLS: [
                        {
                            NAME: 'soul_imprisonment',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'own_troops_necromancy',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'mages_fury',
                            DESCRIPTION: 'Mages attack value +{0}%',
                            EFFECTS: ['attack.{0}.4.1'],
                            STATS: [50, 100, 150, 200, 250]
                        },
                        {
                            NAME: 'mages_inspiration',
                            DESCRIPTION: 'Mages attack value increase by +{0}% each round',
                            EFFECTS: ['mages_inspiration.{0}.4'],
                            STATS: [20, 40, 60, 80, 100]
                        },
                        {
                            NAME: 'podnyat_ubityh_vragov',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'nepodviznie_duhi',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'podnyatie_upokoennyh',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        }
                    ],
                    GENDERS: {
                        MALE: {
                            POSITION: '-504px -930px'
                        },
                        FEMALE: {
                            POSITION: '-420px -930px'
                        }
                    }
                },
                HEALER: {
                    SKILLS: [
                        {
                            NAME: 'vynoslivost_armii',
                            DESCRIPTION: 'Health of all units except mercenaries +{0}%',
                            EFFECTS: ['hp.{0}.4.253'],
                            STATS: [5, 10, 15, 20, 25]
                        },
                        {
                            NAME: 'allies_endurance',
                            DESCRIPTION: 'Health of all units except mercenaries +{0}%, Affects ally',
                            EFFECTS: ['hp.{0}.2.253'],
                            STATS: [5, 10, 15, 20, 25]
                        },
                        {
                            NAME: 'healers_improvement',
                            DESCRIPTION: 'Healers treat +{0}%',
                            EFFECTS: ['improved_healing.{0}.4'],
                            STATS: [3, 6, 9, 12, 15]
                        },
                        {
                            NAME: 'healers_upkeep',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'allies_durability',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'pojertvovat_manu',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'vosstanovlenie_sil',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        }
                    ],
                    GENDERS: {
                        MALE: {
                            POSITION: '-1008px -930px'
                        },
                        FEMALE: {
                            POSITION: '-924px -930px'
                        }
                    }
                },
                ILLUSIONIST: {
                    SKILLS: [
                        {
                            NAME: 'school_of_magic',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'mages_protection',
                            DESCRIPTION: 'Mages defense +{0}',
                            EFFECTS: ['defence.{0}.4.1'],
                            STATS: [10, 20, 30, 40, 50]
                        },
                        {
                            NAME: 'mages_upkeep',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'pohodnye_zerkala',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'illyuziya_geroya',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'tumannyi_fantom',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'zamaskirovat_armiyu',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        }
                    ],
                    GENDERS: {
                        MALE: {
                            POSITION: '-1512px -930px'
                        },
                        FEMALE: {
                            POSITION: '-1428px -930px'
                        }
                    }
                },
                WHISPERER: {
                    SKILLS: [
                        {
                            NAME: 'zaveschanie_monstrov_monstr',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'mana_bleed',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'prizyv_drevnih',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'terror',
                            DESCRIPTION: '{0}% of units don\'t participate in battle, Affects enemy',
                            EFFECTS: ['terror.{0}.1'],
                            STATS: [10, 15, 20, 25, 30]
                        },
                        {
                            NAME: 'naslat_monstrov',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'skovat_geroya',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'skovat_armiyu',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        }
                    ],
                    GENDERS: {
                        MALE: {
                            POSITION: '0px -829px'
                        },
                        FEMALE: {
                            POSITION: '-1764px -930px'
                        }
                    }
                },
                DIFFUSER: {
                    SKILLS: [
                        {
                            NAME: 'enemy_mages_weakness',
                            DESCRIPTION: 'Mages attack value -{0}%, Affects enemy',
                            EFFECTS: ['attack.-{0}.1'],
                            STATS: [20, 40, 60, 80, 100]
                        },
                        {
                            NAME: 'enemy_healers_suppression',
                            DESCRIPTION: 'Healers treat -{0}%, Affects enemy',
                            EFFECTS: ['improved_healing.-{0}.1'],
                            STATS: [10, 20, 30, 40, 50]
                        },
                        {
                            NAME: 'magic_towers_suppression',
                            DESCRIPTION: 'Magic Towers attack value -{0}, Affects enemy',
                            EFFECTS: ['magic_tower_attack.-{0}.1'],
                            STATS: [3, 6, 9, 12, 15]
                        },
                        {
                            NAME: 'diversiya_magicheskih_bashen',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'pohodnye_providcy',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'istinnoe_zrenie_geroya',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'antimagiya',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        }
                    ],
                    GENDERS: {
                        MALE: {
                            POSITION: '-1008px -829px'
                        },
                        FEMALE: {
                            POSITION: '-924px -829px'
                        }
                    }
                }
            }
        },
        GOVERNOR: {
            NAME: 'GOVERNOR',
            SKILLS: [
                {
                    NAME: 'hero_speed',
                    DESCRIPTION: 'Hero\'s squad mission duration {0}',
                    EFFECTS: null,
                    STATS: ['-5%', '-10%', '-15%', '-20%', '-25%']
                },
                {
                    NAME: 'ballista',
                    DESCRIPTION: 'Attack value for each round {0}, Army attack value increase +{1}%',
                    EFFECTS: ['extra_attack.{0}.4', 'attack_amplification.{1}.4'],
                    STATS: [[5000, 2], [10000, 4], [15000, 6], [20000, 8], [25000, 10]]
                },
                {
                    NAME: 'mana_regeneration',
                    DESCRIPTION: 'Mana regeneration +{0}\nMaximum mana +{1}',
                    EFFECTS: null,
                    STATS: [[5, 200], [10, 400], [15, 600], [20, 800], [25, 1000]]
                }
            ],
            SPECIALIZATIONS: {
                BUILDER: {
                    SKILLS: [
                        {
                            NAME: 'uskorenie_stroitelstva',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'towers_master',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'building_strengthening',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'portable_fortification',
                            DESCRIPTION: 'Kills {0} enemy units in fortification round',
                            EFFECTS: ['fortification_kills.{0}.4'],
                            STATS: [500, 1000, 1500, 2000, 2500]
                        },
                        {
                            NAME: 'uskorenniy_remont',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'rasshirenie_stroitelstva',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'stroitelnyi_bum',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        }
                    ],
                    GENDERS: {
                        MALE: {
                            POSITION: '-840px -930px'
                        },
                        FEMALE: {
                            POSITION: '-756px -930px'
                        }
                    }
                },
                DEFENDER: {
                    SKILLS: [
                        {
                            NAME: 'uluchshenie_voskresitelya',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'fortifications_tactics',
                            DESCRIPTION: 'Losses at fortifications +{0}%',
                            EFFECTS: ['fortifications_effectiveness.{0}.4'],
                            STATS: [100, 200, 300, 400, 500]
                        },
                        {
                            NAME: 'defence_skill',
                            DESCRIPTION: 'Maximum defence limit +{0}',
                            EFFECTS: ['max_defence.{0}.4'],
                            STATS: [5, 10, 15, 20, 25]
                        },
                        {
                            NAME: 'zaschita_geroya_ot_magii_monstr',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'rezerv',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'pohodnye_antishpiony',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'generator_buri',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        }
                    ],
                    GENDERS: {
                        MALE: {
                            POSITION: '-1176px -930px'
                        },
                        FEMALE: {
                            POSITION: '-1092px -930px'
                        }
                    }
                },
                MENTOR: {
                    SKILLS: [
                        {
                            NAME: 'uskorennaya_trenirovka',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'uluchshenyi_otbor',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'cheap_training',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'spirit_of_altruism',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'mobilizaciya',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'karantin',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'predannost',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        }
                    ],
                    GENDERS: {
                        MALE: {
                            POSITION: '-1344px -930px'
                        },
                        FEMALE: {
                            POSITION: '-1260px -930px'
                        }
                    }
                },
                GATHERER: {
                    SKILLS: [
                        {
                            NAME: 'hideout',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'kultivaciya_pochvy',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'gornye_instrumenty',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'uluchshenie_zemli',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'himicheskii_sintez',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'ohrana_skladov',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'teleport_resursov',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        }
                    ],
                    GENDERS: {
                        MALE: {
                            POSITION: '-1680px -930px'
                        },
                        FEMALE: {
                            POSITION: '-1596px -930px'
                        }
                    }
                },
                TRADER: {
                    SKILLS: [
                        {
                            NAME: 'side_income',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'monopoly',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'distant_buying',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'carriage_extension',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'optimizaciya_hraneniya',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'uskorenie_priezjim_kupcam',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        },
                        {
                            NAME: 'prizyv_kupcov',
                            DESCRIPTION: 'No Effect in Battle',
                            EFFECTS: null,
                            STATS: [0, 0, 0, 0, 0]
                        }
                    ],
                    GENDERS: {
                        MALE: {
                            POSITION: '-840px -829px'
                        },
                        FEMALE: {
                            POSITION: '-756px -829px'
                        }
                    }
                }
            }
        },
        MONSTER: {
            NAME: 'MONSTER',
            SKILLS: [
                {
                    NAME: 'hero_speed',
                    DESCRIPTION: 'Hero\'s squad mission duration {0}',
                    EFFECTS: null,
                    STATS: ['-5%', '-10%', '-15%', '-20%', '-25%']
                },
                {
                    NAME: 'trebuchet',
                    DESCRIPTION: 'Enhances building speed',
                    EFFECTS: null,
                    STATS: ['-10', '-20', '-30', '-40', '-50']
                },
                {
                    NAME: 'mana_regeneration',
                    DESCRIPTION: 'Mana regeneration +{0}, Maximum mana +{1}',
                    EFFECTS: null,
                    STATS: [[5, 200], [10, 400], [15, 600], [20, 800], [25, 1000]]
                }
            ],
            SPECIALIZATIONS: {
                JUGGERNAUT: {
                    SKILLS: [
                        {
                            NAME: ' SKILL 12',
                            DESCRIPTION: 'Enhances building speed',
                            ACTIVE: false,
                            EFFECT: true,
                            STATS: ['-10', '-20', '-30', '-40', '-50']
                        },
                        {
                            NAME: ' SKILL 12',
                            DESCRIPTION: 'Enhances building speed',
                            ACTIVE: false,
                            EFFECT: true,
                            STATS: ['-10', '-20', '-30', '-40', '-50']
                        },
                        {
                            NAME: ' SKILL 12',
                            DESCRIPTION: 'Enhances building speed',
                            ACTIVE: false,
                            EFFECT: true,
                            STATS: ['-10', '-20', '-30', '-40', '-50']
                        },
                        {
                            NAME: ' SKILL 12',
                            DESCRIPTION: 'Enhances building speed',
                            ACTIVE: false,
                            EFFECT: true,
                            STATS: ['-10', '-20', '-30', '-40', '-50']
                        },
                        {
                            NAME: ' SKILL 12',
                            DESCRIPTION: 'Enhances building speed',
                            ACTIVE: false,
                            EFFECT: true,
                            STATS: ['-10', '-20', '-30', '-40', '-50']
                        },
                        {
                            NAME: ' SKILL 12',
                            DESCRIPTION: 'Enhances building speed',
                            ACTIVE: false,
                            EFFECT: true,
                            STATS: ['-10', '-20', '-30', '-40', '-50']
                        },
                        {
                            NAME: ' SKILL 12',
                            DESCRIPTION: 'Enhances building speed',
                            ACTIVE: false,
                            EFFECT: true,
                            STATS: ['-10', '-20', '-30', '-40', '-50']
                        }
                    ],
                    GENDERS: {
                        MALE: {
                            POSITION: '-588px -627px'
                        },
                        FEMALE: {
                            POSITION: '-504px -627px'
                        }
                    }
                },
                WARLOCK: {
                    SKILLS: [
                        {
                            NAME: ' SKILL 12',
                            DESCRIPTION: 'Enhances building speed',
                            ACTIVE: false,
                            EFFECT: true,
                            STATS: ['-10', '-20', '-30', '-40', '-50']
                        },
                        {
                            NAME: ' SKILL 12',
                            DESCRIPTION: 'Enhances building speed',
                            ACTIVE: false,
                            EFFECT: true,
                            STATS: ['-10', '-20', '-30', '-40', '-50']
                        },
                        {
                            NAME: ' SKILL 12',
                            DESCRIPTION: 'Enhances building speed',
                            ACTIVE: false,
                            EFFECT: true,
                            STATS: ['-10', '-20', '-30', '-40', '-50']
                        },
                        {
                            NAME: ' SKILL 12',
                            DESCRIPTION: 'Enhances building speed',
                            ACTIVE: false,
                            EFFECT: true,
                            STATS: ['-10', '-20', '-30', '-40', '-50']
                        },
                        {
                            NAME: ' SKILL 12',
                            DESCRIPTION: 'Enhances building speed',
                            ACTIVE: false,
                            EFFECT: true,
                            STATS: ['-10', '-20', '-30', '-40', '-50']
                        },
                        {
                            NAME: ' SKILL 12',
                            DESCRIPTION: 'Enhances building speed',
                            ACTIVE: false,
                            EFFECT: true,
                            STATS: ['-10', '-20', '-30', '-40', '-50']
                        },
                        {
                            NAME: ' SKILL 12',
                            DESCRIPTION: 'Enhances building speed',
                            ACTIVE: false,
                            EFFECT: true,
                            STATS: ['-10', '-20', '-30', '-40', '-50']
                        }
                    ],
                    GENDERS: {
                        MALE: {
                            POSITION: '-840px -627px'
                        },
                        FEMALE: {
                            POSITION: '-756px -627px'
                        }
                    }
                },
                ANCIENT: {
                    SKILLS: [
                        {
                            NAME: 'podavlenuie_vseh_voysk',
                            DESCRIPTION: 'Enhances building speed',
                            EFFECTS: null,
                            STATS: ['-10', '-20', '-30', '-40', '-50']
                        },
                        {
                            NAME: 'attackers_weakness',
                            DESCRIPTION: 'Enhances building speed',
                            EFFECT: null,
                            STATS: ['-10', '-20', '-30', '-40', '-50']
                        },
                        {
                            NAME: 'dikiy_uzas',
                            DESCRIPTION: 'Enhances building speed',
                            EFFECT: null,
                            STATS: ['-10', '-20', '-30', '-40', '-50']
                        },
                        {
                            NAME: 'enemy_healers_suppression',
                            DESCRIPTION: 'Enhances building speed',
                            EFFECT: null,
                            STATS: ['-10', '-20', '-30', '-40', '-50']
                        },
                        {
                            NAME: 'soul_imprisonment',
                            DESCRIPTION: 'Enhances building speed',
                            EFFECT: null,
                            STATS: ['-10', '-20', '-30', '-40', '-50']
                        },
                        {
                            NAME: 'protection',
                            DESCRIPTION: 'Enhances building speed',
                            EFFECT: null,
                            STATS: ['-10', '-20', '-30', '-40', '-50']
                        },
                        {
                            NAME: 'ally_protection',
                            DESCRIPTION: 'Enhances building speed',
                            EFFECT: null,
                            STATS: ['-10', '-20', '-30', '-40', '-50']
                        }
                    ],
                    GENDERS: {
                        MALE: {
                            POSITION: '-420px -627px'
                        },
                        FEMALE: {
                            POSITION: '-336px -627px'
                        }
                    }
                },
            }
        },
        ADVANCED_SKILLS: {
            ALL: [
                {
                    NAME: 'invisibility',
                    DESCRIPTION: 'No Effect in Battle',
                    EFFECTS: null,
                    STATS: [0]
                },
                {
                    NAME: 'locality_adaptation',
                    DESCRIPTION: 'Attack value warriors +50% on mountain terrain, ' +
                        'Attack value riders +50% on forrest terrain, ' +
                        'Attack value airborne +50% on desert terrain, ' +
                        'Attack value archers +50% on steppe terrain, ' +
                        'Attack value mercenaries +50% on underground terrain,',
                    EFFECTS: [
                        "attack.50.4.64.63.2",
                        "attack.50.4.32.63.4",
                        "attack.50.4.16.63.16",
                        "attack.50.4.8.63.8",
                        "attack.50.4.2.63.1"
                    ],
                    STATS: [0]
                },
                {
                    NAME: 'super_diversion',
                    DESCRIPTION: 'No Effect in Battle',
                    EFFECTS: null,
                    STATS: [0]
                },
                {
                    NAME: 'siege_tower',
                    DESCRIPTION: 'Army attack value increase +10%, All units defense value +10, ' +
                        'Magic towers Attack value -50%, Affects Enemy, ' +
                        'Towers Attack value -50%, Affects Enemy',
                    EFFECTS: [
                        "attack.10.4",
                        "defence.10.4",
                        "magic_tower_attack.-50.1",
                        "tower_attack.-50.1"
                    ],
                    STATS: [0],
                }],
            COMMANDER: [
                {
                    NAME: 'invisibility',
                    DESCRIPTION: 'No Effect in Battle',
                    EFFECTS: null,
                    STATS: [0]
                },
                {
                    NAME: 'locality_adaptation',
                    DESCRIPTION: 'Attack value warriors +50% on mountain terrain, ' +
                        'Attack value riders +50% on forrest terrain, ' +
                        'Attack value airborne +50% on desert terrain, ' +
                        'Attack value archers +50% on steppe terrain, ' +
                        'Attack value mercenaries +50% on underground terrain,',
                    EFFECTS: [
                        "attack.50.4.64.63.2",
                        "attack.50.4.32.63.4",
                        "attack.50.4.16.63.16",
                        "attack.50.4.8.63.8",
                        "attack.50.4.2.63.1"
                    ],
                    STATS: [0]
                },
                {
                    NAME: 'cursed_lighthouse',
                    DESCRIPTION: 'Incoming attacks duration -25%',
                    EFFECTS: null,
                    STATS: [0],
                },
                {
                    NAME: 'siege_tower',
                    DESCRIPTION: 'Army attack value increase +10%, All units defense value +10, ' +
                        'Magic towers Attack value -50%, Affects Enemy, ' +
                        'Towers Attack value -50%, Affects Enemy',
                    EFFECTS: [
                        "attack.10.4",
                        "defence.10.4",
                        "magic_tower_attack.-50.1",
                        "tower_attack.-50.1"
                    ],
                    STATS: [0],
                }]
        },
        get ALL() {
            return {
                ...this.PLAYERS,
                MONSTER: {...this.MONSTER, EMPTY_SKILL_SLOTS: "skill_stub_monster"}
            }
        },
        get PLAYERS() {
            return {
                COMMANDER: {
                    ...this.COMMANDER,
                    ADVANCED_SKILLS: this.ADVANCED_SKILLS.COMMANDER,
                    EMPTY_SKILL_SLOTS: "skill_stub_commander"
                },
                STRATEGIST: {
                    ...this.STRATEGIST,
                    ADVANCED_SKILLS: this.ADVANCED_SKILLS.ALL,
                    EMPTY_SKILL_SLOTS: "skill_stub_warrior"
                },
                WIZARD: {
                    ...this.WIZARD,
                    ADVANCED_SKILLS: this.ADVANCED_SKILLS.ALL,
                    EMPTY_SKILL_SLOTS: "skill_stub_mage"
                },
                GOVERNOR: {
                    ...this.GOVERNOR,
                    ADVANCED_SKILLS: this.ADVANCED_SKILLS.ALL,
                    EMPTY_SKILL_SLOTS: "skill_stub_miner"
                },
            }
        }
    }
}

export default HEROES;