// Libs
import {Fragment, useState} from "react";
import {Button, Modal} from "react-bootstrap";

// Custom
import BattleSide from "../BattleField/BattleSide";
import CONSTANTS from "../../data/CONSTANTS";
import calculatorStore from "../../stores/CalculatorStore";
import {BATTLE_STAGES} from "../../data/BattleStages";

const DetailedResults = () => {
	const [show, setShow] = useState(false);

	const closeModal = () => setShow(false);
	const openModal = () => setShow(true);

	return (
			<>
				<div className="d-grid gap-2 details-button">
					<Button variant="info" size="lg" className="shadow-none" id='detailed_results_btn' onClick={openModal}>
						Detailed results
					</Button>
				</div>

				<Modal show={show} onHide={closeModal} size="lg" centered className="detailed_results_modal">
					<Modal.Body>
						<div className="battle_results detailed_results">

							{Object.keys(calculatorStore.battleResults.stages).map((stage, index) => {
								const showStage = calculatorStore.battleResults.stages[stage].active
								return stage === BATTLE_STAGES.BATTLE_ROUND
										? calculatorStore.battleResults.stages[stage]
												.map((n, roundNumber) =>
														<BattleRound key={`${index}_${roundNumber}`} name={stage} number={roundNumber}/>)
										: showStage
												? <BattleRound key={`${stage}-${index}`} name={stage}/>
												: <Fragment key={`${stage}-${index}`}/>
							})}

						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={closeModal}>
							Close
						</Button>
					</Modal.Footer>
				</Modal>
			</>
	)
}

const BattleRound = ({name, number}) => {
	const isBattleRound = name === BATTLE_STAGES.BATTLE_ROUND
	return <div className="battle_stage">
				{name} {isBattleRound && number + 1}
				<BattleSide side={CONSTANTS.ARMY.SIDE.ATTACKER} type="results" round={name} roundNumber={number}/>
				<BattleSide side={CONSTANTS.ARMY.SIDE.DEFENDER} type="results" round={name} roundNumber={number}/>
			</div>

}


export default DetailedResults;