// Libs
import {Button, Modal, Tab, Tabs} from "react-bootstrap";
import {useState} from "react";
import {observer} from "mobx-react";

// Custom
import HeroesList from "./HeroesList";
import HeroFaceComponent from "./HeroFaceComponent";
import HeroEffects from "../Effects/HeroEffects";
import ArtifactsTab from "../Artifacts/ArtifactsTab";
import HeroDoll from "./HeroDoll";
import HeroSkills from "../Skills/HeroSkills";
import EditArtifactTab from "../Artifacts/EditArtifactTab";

const HeroModal = (props) => {
	let {race, hero} = props;

	const heroIsPresent = !!hero.heroClass
	const [show, setShow] = useState(false);

	const closeModal = () => setShow(false);
	const openModal = () => setShow(true);


	const switchTab = (key) => {
		hero.changeActiveTab(key)
	}

	return (
		<>
			<div className="hero_modal" onClick={openModal}/>
			<Modal show={show} onHide={closeModal} size="lg" centered className="hero_modal-modal">
				<Modal.Body>
					<div className="hero_modal_body">

						<div className="equipment">

							<Tabs defaultActiveKey="hero" id="hero_equipment_tabs" className="mb-3">
								<Tab eventKey="hero" title="" className="box hero_tab">
									<div className="hero_panel">
										<HeroFaceComponent race={race} hero={hero}>
											<div className="remove_hero">
												<Button onClick={() => hero.removeHero()} disabled={!heroIsPresent}
												        className="shadow-none"/>
											</div>
										</HeroFaceComponent>
										{process.env.REACT_APP_LOCAL_DEV === 'true' ? <div className="helper_buttons">
												<Button variant="primary" onClick={() => {
													hero.effects.loadEffectsList('host');
													switchTab('effects')
												}}>Host</Button>
												<Button variant="primary"
												        onClick={() => hero.effects.loadEffectsList('defender')}>Defender</Button>
												<Button variant="primary"
												        onClick={() => hero.effects.loadEffectsList('snow')}>Snow</Button>
												<Button variant="primary"
												        onClick={() => hero.effects.runTest()}>test</Button>
												<Button variant="primary" onClick={() => hero.effects.list()}>list</Button>
											</div> :
											<div/>}
									</div>
									<HeroDoll hero={hero}/>
								</Tab>
							</Tabs>

						</div>

						<div className="hero_utilities">
							<Tabs activeKey={hero.activeTab} id="hero_utilities_tabs"
							      className="mb-3" onSelect={switchTab}>
								<Tab eventKey="skills" title=""
								     className="box hero_tab"
								     disabled={!heroIsPresent}>
									<HeroSkills hero={hero}/>
								</Tab>

								<Tab eventKey="casts" title=""
								     className="box hero_tab"
								     disabled={!heroIsPresent}>
									Work in progress. Battle scrolls will be here.
								</Tab>

								<Tab eventKey="effects" title=""
								     className="box hero_tab"
								     disabled={!heroIsPresent}>
									<HeroEffects effects={hero.effects}/>
								</Tab>

								<Tab eventKey="artifacts" title=""
								     className="box hero_tab"
								     disabled={!heroIsPresent}>
									<ArtifactsTab hero={hero}/>
								</Tab>

								<Tab eventKey="runes" title=""
								     className="box hero_tab"
								     disabled={!heroIsPresent}>
									<EditArtifactTab/>
								</Tab>

								<Tab eventKey="hero_faces" title="" className="box hero_tab">
									<HeroesList pickHero={(newHero) => {
										hero.pickHero(newHero);
									}} race={race}/>
								</Tab>
							</Tabs>

						</div>

					</div>
				</Modal.Body>
				<Modal.Footer>
					<div className="hero_modal_footer">
						<Button variant="secondary" onClick={closeModal}>
							Close
						</Button>
					</div>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default observer(HeroModal);