// Libs
import {observer} from "mobx-react";
import {createRef, useState} from "react";
import Tippy from "@tippyjs/react"

// Custom
import UnitInfo from "./UnitInfo";

const Unit = (props) => {
    const {name, lvl, sprite, count, updateUnitLvl, updateCount, race} = props
    const [mouseHoverInput, setMouseHoverInput] = useState(false)
    const [tippyVisible, setTippyVisible] = useState(false)
    const inputRef = createRef()

    let noUnits = count === 0;
    let cardStyle = {
        backgroundPosition: sprite,
        filter: noUnits ? 'grayscale(100%) brightness(70%)' : 'none'
    }

    const mouseEnter = () => {
        inputRef.current.style.zIndex = "auto"
        inputRef.current.focus();
        inputRef.current.select();
        setTippyVisible(true)
    };

    const mouseLeave = () => {
        inputRef.current.style.zIndex = "-2"
        setTippyVisible(false)
    }

    const focus = () => {
        inputRef.current.style.zIndex = "auto"
    }

    const blur = () => {
        inputRef.current.style.zIndex = "-2"
    }

    const inputChange = e => {
        if (/\D/.test(e.target.value)) {
            e.target.value = e.target.value.replace(/\D/g, '')
        }
        updateCount(e.target.value)
    }

    const inputKeyPress = e => {
        const allowedKeys = ['Tab', 'Backspace']

        if (/\D/.test(e.key) && !allowedKeys.includes(e.key)) {
            // do not allow other keys than digits and allowed keys
            e.preventDefault()
        } else {
            setTippyVisible(false)
        }

        if (e.key === 'Enter') {
            mouseLeave()
        }
    }

    return (
        <Tippy placement="right"
               arrow={false}
               visible={tippyVisible}
               theme={'army-unit'}
               content={<UnitInfo {...props}/>}>
            <div className={`unit_border ${lvl === 4 ? 'lvl4-' + race : ''}`}
                 onMouseEnter={e => mouseEnter(e)}
                 onMouseLeave={e => mouseLeave(e)}
                 onFocus={e => focus()}
                 onBlur={e => blur()}
                 onClick={() => !mouseHoverInput && updateUnitLvl(lvl + 1)}>
                <div className={`unit unit-${props.race} unit-${name}`} style={cardStyle}>
                </div>
                <p>{noUnits ? '' : count}</p>
                <input type="text"
                       ref={inputRef}
                       value={count}
                       onMouseEnter={() => setMouseHoverInput(true)}
                       onMouseLeave={() => setMouseHoverInput(false)}
                       onKeyDown={e => inputKeyPress(e)}
                       onChange={e => inputChange(e)}/>
            </div>
        </Tippy>
    )
}

export default observer(Unit);