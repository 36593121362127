// Libs
import {observer} from "mobx-react";

// Custom
import calculatorStore from "../../stores/CalculatorStore";
import BattleSide from "../BattleField/BattleSide";
import CONSTANTS from "../../data/CONSTANTS";
import DetailedResults from "./DetailedResults";
import {BATTLE_STAGES} from "../../data/BattleStages";

const BattleResults = () => {
	const battleHappened = calculatorStore.battleResults[CONSTANTS.ARMY.SIDE.ATTACKER]
			&& calculatorStore.battleResults[CONSTANTS.ARMY.SIDE.DEFENDER]
	const attackerWon = calculatorStore.winner === CONSTANTS.ARMY.SIDE.ATTACKER

	return battleHappened
			? (<>
					<div className="box armies_box battle_results extra_margin_top">
						<div className="general_results">

							<div className={attackerWon ? 'winner' : 'loser'}>
								{CONSTANTS.ARMY.SIDE.ATTACKER.toUpperCase()}S {attackerWon ? 'WON!' : 'LOST!'}
							</div>
							<BattleSide side={CONSTANTS.ARMY.SIDE.ATTACKER} type="results" round={BATTLE_STAGES.SUMMARY} />

							<div className={!attackerWon ? 'winner' : 'loser'}>
								{CONSTANTS.ARMY.SIDE.DEFENDER.toUpperCase()}S {!attackerWon ? 'WON!' : 'LOST!'}
							</div>
							<BattleSide side={CONSTANTS.ARMY.SIDE.DEFENDER} type="results" round={BATTLE_STAGES.SUMMARY}/>

						</div>
					</div>

					<DetailedResults/>
				</>)
			: <></>
}

export default observer(BattleResults);