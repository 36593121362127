// Libs
import CONSTANTS from "../../../data/CONSTANTS";
import {capitalize, getEffectKeys, unCamel, unSnake} from "../../../utilities";
import {values} from "mobx";

const EffectLabel = ({effect, removeEffect, extraClass}) => {
    const affectedParties = Object.keys(effect.parties).filter(key => effect.parties[key])
        .join(', ') + '.'

    const affectedUnits = !Object.values(effect.units).includes(false) ? ''
        : Object.keys(effect.units).filter(key => effect.units[key]).map(unit => <i
            className={`unit-icon ${unit}`} key={unit}/>);

    const getConditionLabel = (cond, type) => {
        const condVals = effect[cond]
        let value = ''
        switch (type) {
            case 'checkbox':
                value = !Object.values(condVals).includes(false) ? '' :
                    Object.keys(condVals).filter(key => condVals[key]).join(', ')
                break;
            case 'radio':
                value = condVals.any ? '' : Object.keys(condVals).find(k => condVals[k])
                break;
            default:
        }

        let condName, additive = ''

        let processName = unCamel

        switch (cond){
            case 'enemy':
                condName = 'In battle against '
                break;
            case 'terrain':
                condName = 'On '
                additive = ' terrain'
                processName = unSnake
                break
            case 'domain':
                condName = 'In the '
                break
            case 'mission':
                condName = 'When '
                value = value === 'defense' ? 'defending': value
                break
            default:
                condName = capitalize(cond) + ': '
        }

        return !value ? '' : condName + processName(value) + additive + '.'
    }


    const checkboxCond = ['race', 'terrain'].map(cond => getConditionLabel(cond, 'checkbox')).join(' ')
    const radioCond = getEffectKeys('radio').map(cond => getConditionLabel(cond, 'radio')).join(' ')

    const sign = effect.type === CONSTANTS.EFFECTS.TERROR ? '' : '+'
    let displayedAmount = effect.amount > 0 ? `${sign}${effect.amount}` : `${effect.amount}`
    const usePercent = ![CONSTANTS.EFFECTS.DEFENCE,
        CONSTANTS.EFFECTS.EXTRA_HEALS,
        CONSTANTS.EFFECTS.EXTRA_ATTACK,
        CONSTANTS.EFFECTS.MAX_DEFENCE,
        CONSTANTS.EFFECTS.MAGES_DMG_REDUCTION].includes(effect.type)
    displayedAmount += usePercent ? '%' : ''

    const effectColor = effect.type === CONSTANTS.EFFECTS.TERROR ? 'negative'
        : effect.amount > 0 ? 'positive' : 'negative';

    const getDisplayText = () => {
        switch (effect.type) {
            case CONSTANTS.EFFECTS.FORTIFICATION_KILLS:
                return <>
                    Kills <span className={effectColor}>{effect.amount}</span> enemy units in
                    fortifications round.
                </>
            case CONSTANTS.EFFECTS.FEARLESSNESS:
                return <>
                    No retreat in terror round!
                </>
            case CONSTANTS.EFFECTS.CATAPULT:
                return <>
                    Catapult
                </>
            case CONSTANTS.EFFECTS.IGNORE_MERCY_KILLING:
                return <>
                    Ignore enemy mercy killing effect.
                </>
            case CONSTANTS.EFFECTS.NECROMANCY:
                const parties = Object.keys(effect.parties).filter(key=>effect.parties[key]).map(key=> {
                    switch (key){
                        case 'self':
                            return 'of your killed units'
                        case 'ally':
                            return 'killed allies'
                        case 'enemy':
                            return 'killed enemy troops'
                    }
                }).join(' ')
                return <>
                    Turns <span className='positive'>{effect.amount}%</span> {parties} into Mercenaries
                </>
            default:
                return <>
                    {affectedUnits} <span
                    className={effectColor}>{displayedAmount}</span> {effect.type.replaceAll('_', ' ')}.
                    Affects {affectedParties} {checkboxCond} {radioCond}
                </>
        }
    }

    return <p className={`effect ${extraClass || ''}`}>
        {removeEffect && <span className="remove_effect" onClick={() => removeEffect(effect.id)}/>}
        {getDisplayText()}
    </p>
}

export default EffectLabel;