import {makeAutoObservable, toJS} from "mobx";
import {createRef} from "react";
import {v4 as uuidv4} from 'uuid';

import CONSTANTS from "../data/CONSTANTS";
import {allPropsValue, compareEffects, decodeEffect, deepClone, encodeEffect, getEffectKeys} from "../utilities";
import calculatorStore from "./CalculatorStore";
import {EffectsLists} from "../data/EffectsLists";
import {NewEffectProps} from "../data/NewEffectProps";

class EffectsStore {
	hero = null
	effectsList = []
	newEffect = NewEffectProps
	disableInputs = {
		effectTypeInput: false,
		amountInput: false,
		parties: false,
		units: false,
		race: false,
		terrain: false,
		domain: false,
		enemy: false,
		mission: false
	}
	amountInput = createRef()
	effectTypeInput = createRef()

	constructor({hero}) {
		makeAutoObservable(this)
		this.hero = hero
		this.newEffect.manual = true
	}

	selectEffect(effect) {
		this.newEffect.type = effect;
		this.toggleInput(true)
		this.disableInputs.units = false
		this.toggleConditionAll("units", true)
		this.toggleConditionRadio('domain', 'any')
		this.toggleConditionRadio('enemy', 'any')
		this.toggleConditionRadio('mission', 'any')
		this.toggleConditionRadio('defender', 'any')
		this.toggleConditionRadio('self', 'any')

		// preselect some conditions to make it easier
		switch (effect) {
			case CONSTANTS.EFFECTS.TERROR:
				this.updateAmount(50)
				this.toggleConditionAll("parties", false)
				this.newEffect.parties.enemy = true
				this.toggleConditionAll("units", true)
				this.disableInputs.units = true
				break;

			case CONSTANTS.EFFECTS.FEARLESSNESS:
			case CONSTANTS.EFFECTS.CATAPULT:
				this.toggleInput(false)
				this.updateAmount(0)
				this.toggleConditionAll("parties", false)
				this.newEffect.parties.self = true
				this.disableInputs.units = true
				break;

			case CONSTANTS.EFFECTS.ATTACK:
			case CONSTANTS.EFFECTS.EXTRA_ATTACK:
			case CONSTANTS.EFFECTS.ATTACK_AMPLIFICATION:
			case CONSTANTS.EFFECTS.DEFENCE:
			case CONSTANTS.EFFECTS.HP:
			case CONSTANTS.EFFECTS.MAGES_DMG_REDUCTION:
			case CONSTANTS.EFFECTS.MAGES_INSPIRATION:
				this.toggleConditionAll("parties", false)
				this.newEffect.parties.self = true
				break;

			case CONSTANTS.EFFECTS.FORTIFICATION_KILLS:
				this.toggleConditionAll("parties", false)
				this.newEffect.parties.self = true
				this.disableInputs.units = true
				break;

			case CONSTANTS.EFFECTS.PREDATOR_DAMAGE:
				this.toggleConditionAll("units", false)
				this.newEffect.units.warrior = true
				this.newEffect.units.rider = true
				this.newEffect.units.flying = true
				this.newEffect.units.ranged = true
				break;

			case CONSTANTS.EFFECTS.EXTRA_HEALS:
			case CONSTANTS.EFFECTS.IMPROVED_HEALING:
			case CONSTANTS.EFFECTS.IMPROVED_MAGES:
			case CONSTANTS.EFFECTS.GOOD_LUCK:
			case CONSTANTS.EFFECTS.BAD_LUCK:
				this.disableInputs.units = true
				break;

			default:
		}
	}

	toggleInput(val) {
		this.amountInput.current.disabled = !val
	}

	/**
	 * Update the amount input value of the new effect
	 * @param newVal
	 */
	updateAmount(newVal) {
		this.newEffect.amount = newVal;
		this.amountInput.current.value = newVal;
	}

	updateSelectedEffect(effect) {
		this.newEffect.type = effect
		this.effectTypeInput.current.value = effect
	}

	toggleCondition(condition, choice) {
		this.newEffect[condition][choice] = !this.newEffect[condition][choice]
	}

	toggleConditionAll(condition, newVal) {
		allPropsValue(this.newEffect[condition], newVal)
	}

	toggleConditionRadio(condition, choice) {
		allPropsValue(this.newEffect[condition], false)
		this.newEffect[condition][choice] = true
	}

	addEffect(newEffect) {
		const effect = newEffect ? deepClone(newEffect) : deepClone(this.newEffect)
		effect.id = uuidv4()

		effect.initiator = {
			// fraction:
			side: this.hero.army.side,
			race: this.hero.race,
			id: this.hero.army.id,
			fraction: this.hero.army.fraction,
			abraKadabra: true

		}

		this.effectsList.push(effect)

		if (effect.type === CONSTANTS.EFFECTS.NECROMANCY) {
			this.hero.army.receivePresent(effect)
		} else {
			if (effect.parties.self) {
				this.hero.army.receivePresent(effect)
			}
			if (effect.parties.ally) {
				this.hero.army.allies.forEach(alliedArmy => alliedArmy.receivePresent(effect))
			}
			if (effect.parties.enemy) {
				this.hero.army.enemies.forEach(enemyArmy => enemyArmy.receivePresent(effect))
			}
		}
	}

	reCalculateEffects() {
		const effects = deepClone(this.effectsList)
		this.effectsList = []
		effects.forEach(e => this.addEffect(e))
	}

	findEffectId(e) {
		const found = this.effectsList.find(eff => compareEffects(e, eff))
		return found.id
	}

	removeEffect(id, updateInputs = true) {
		const index = this.effectsList.map(e => e.id).indexOf(id)
		const effect = this.effectsList[index]

		if (updateInputs) {
			this.effectsList.splice(index, 1)
			for (const k in effect) getEffectKeys().includes(k) && (this.newEffect[k] = effect[k])
			this.updateAmount(effect.amount)
			this.updateSelectedEffect(effect.type)
		}

		if (effect.type === CONSTANTS.EFFECTS.NECROMANCY) {
			this.hero.army.cancelPresent(id)
		} else {
			if (effect.parties.self) {
				this.hero.army.cancelPresent(id)
			}
			if (effect.parties.ally) {
				this.hero.army.allies.forEach(alliedArmy => alliedArmy.cancelPresent(id))
			}
			if (effect.parties.enemy) {
				this.hero.army.enemies.forEach(enemyArmy => enemyArmy.cancelPresent(id))
			}
		}
	}

	removeAll() {
		this.effectsList.forEach(effect => this.removeEffect(effect.id, false))
		this.effectsList = []
	}

	loadEffectsList(key) {
		const effectIds = this.effectsList.map(e => e.id)

		effectIds.forEach(id => {
			this.removeEffect(id)
		})

		if (Object.keys(EffectsLists).includes(key)) {
			EffectsLists[key].forEach(effect => this.addEffect(decodeEffect(effect)))
		} else {
			console.log('no effects list found with key', key)
		}
	}

	runTest() {
		calculatorStore.armies.attacker[0].hero.pickHero({
			"heroClass": "GENERAL",
			"specialisation": "HUNTER",
			"gender": "female",
			"position": "-1176px -627px",
			"race": "elf"
		})

		calculatorStore.armies.attacker[0].hero.effects.loadEffectsList('snow')
		calculatorStore.armies.attacker[0].updateAllUnitsLvl(4)

		calculatorStore.addArmy('defender')
		calculatorStore.addArmy('defender');

		[30000, 420000, 0, 310000, 0, 40000, 0, 0].forEach((num, i) => calculatorStore.armies.attacker[0].updateUnitCount(i, num));

		[44602, 26679, 21229, 11298, 8167, 8832, 21000, 7278].forEach((num, i) => calculatorStore.armies.defender[0].updateUnitCount(i, num));
		[48918, 29748, 19176, 14868, 12612, 8976, 32394, 7284].forEach((num, i) => calculatorStore.armies.defender[1].updateUnitCount(i, num));
		[61224, 33324, 22794, 13854, 14844, 10320, 39558, 6882].forEach((num, i) => calculatorStore.armies.defender[2].updateUnitCount(i, num));

		calculatorStore.armies.defender[0].changeRace('monster')
		calculatorStore.armies.defender[1].changeRace('monster')
		calculatorStore.armies.defender[2].changeRace('monster')


		calculatorStore.armies.attacker[0].updateFortune('custom')

		calculatorStore.armies.defender[0].updateFortune('custom')
		calculatorStore.armies.defender[1].updateFortune('custom')
		calculatorStore.armies.defender[2].updateFortune('custom')


		calculatorStore.armies.defender[0].hero.effects.loadEffectsList('host')
		calculatorStore.armies.defender[1].hero.effects.loadEffectsList('defender')
		calculatorStore.armies.defender[2].hero.effects.loadEffectsList('defender')

		calculatorStore.armies.defender[0].hero.pickHero({
			"heroClass": "MONSTER",
			"specialisation": "ANCIENT",
			"gender": "female",
			"position": "-336px -627px",
			"race": "monster"
		})
		calculatorStore.armies.defender[1].hero.pickHero({
			"heroClass": "MONSTER",
			"specialisation": "JUGGERNAUT",
			"gender": "male",
			"position": "-588px -627px",
			"race": "monster"
		})
		calculatorStore.armies.defender[2].hero.pickHero({
			"heroClass": "MONSTER",
			"specialisation": "JUGGERNAUT",
			"gender": "male",
			"position": "-588px -627px",
			"race": "monster"
		})

	}

	hasEffect(name) {
		let count = 0
		this.effectsList.forEach(e => count += e.type === name ? 1 : 0)
		return count
	}

	list() {
		// console.log('efefcts', this.effectsList)
		const encodedEffects = this.effectsList.map(effect => encodeEffect(effect))
		console.log('list effects', encodedEffects)
		// console.log('decoded effects', toJS(this.hero.army.receivedEffects))
	}

	saveManual() {
		return this.effectsList.filter(e => e.manual).map(e => encodeEffect(e))
	}
}

export default EffectsStore