// Libs
import {observer} from "mobx-react";
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'

// Data
import UNIT_SPRITES from "../../data/UNIT_SPRITES";
import {BATTLE_STAGES} from "../../data/BattleStages";

// Custom Components
import UnitInfo from "../Army/UnitInfo";
import FortificationInfo from "../BattleField/FortificationInfo";


const UnitNonEditable = (props) => {
    switch (props.type) {
        case 'unit': {
            const {name, lvl, sprite, race, results, stage, roundNumber} = props
            const isBattleRound = stage === BATTLE_STAGES.BATTLE_ROUND

            if (!results[stage]) {
                console.error('STAGE NOT FOUND!!!', `'${stage}'`, 'UnitNonEditable')
                return null
            }

            const {initial, gained, lost} = isBattleRound ? results.battle_round[roundNumber] : results[stage]

            let noUnits = initial === 0 && lost >= gained;
            let cardStyle = {
                backgroundPosition: sprite,
                filter: noUnits ? 'grayscale(100%) brightness(70%)' : 'none'
            }

            return (
                <Tippy placement="right" arrow={false} theme={'army-unit'} content={<UnitInfo {...props}/>}>
                    <div className={`unit_border ${lvl === 4 ? 'lvl4-' + race : ''}`}>
                        <div className={`unit unit-${race} unit-${name}`} style={cardStyle}>
                        </div>
                        <p className="initial_amount">{noUnits ? '' : initial}</p>
                        {(gained > lost) ?
                            <p className="gained_amount">{noUnits ? '' : '+' + (gained - lost)}</p> :
                            <p className="lost_amount">{noUnits ? '' : '-' + (lost - gained)}</p>
                        }
                        <p className="left_amount">{noUnits ? '' : initial - lost + gained}</p>
                    </div>
                </Tippy>
            )
        }

        case 'turret':
            const {race, fort, roundNumber, towerId} = props
            const infoProps = {type: fort.type, lvl: fort.lvl, roundNumber, dmg: fort.dmg}
            const sprite = UNIT_SPRITES[race][fort.type]

            return (
                <Tippy key={towerId + 8} placement="right" arrow={false} theme={'army-unit'}
                       content={<FortificationInfo {...infoProps}/>}>
                    <div className="unit_border">
                        <div className={`unit unit-${race}`} style={{backgroundPosition: sprite}}>
                        </div>
                    </div>
                </Tippy>)

        default: {
            return <h3>no such units</h3>
        }
    }


}

export default observer(UnitNonEditable);
