// Libs
import {Button, Form, FormControl, InputGroup, OverlayTrigger, Popover} from "react-bootstrap";
import {observer} from "mobx-react";
import {getEffectKeys, unSnake} from "../../../utilities";
import CONSTANTS from "../../../data/CONSTANTS";
import EffectLabel from "./EffectLabel";
import EffectCondition from "./EffectCondition";

const HeroEffects = ({effects}) => {
    return (
        <div className="hero_effects_tab">
            <InputGroup>
                <Form.Select defaultValue={effects.newEffect.type || "default"}
                             id="select_effect"
                             ref={effects.effectTypeInput}
                             onChange={(evt) => effects.selectEffect(evt.target.value)}>
                    <option disabled value="default">Select Effect</option>
                    {Object.values(CONSTANTS.EFFECTS)
                        .map(effect => <option key={effect} value={effect}>{unSnake(effect)}</option>)}
                </Form.Select>

                <FormControl ref={effects.amountInput} className="effect_value"
                             defaultValue={effects.newEffect.amount}
                             disabled={!effects.newEffect.type}
                             onChange={(e) => effects.updateAmount(e.target.value)}/>

                <OverlayTrigger
                    trigger="click"
                    placement="bottom"
                    rootClose
                    overlay={
                        <Popover>
                            <Popover.Header as="h3">Effect Conditions</Popover.Header>
                            <Popover.Body>
                                <div className="effect-conditions">
                                    {['checkbox', 'radio'].map(tp => getEffectKeys(tp)
                                        .map((cond, i) => <EffectCondition key={i} effects={effects} condition={cond}
                                                                           type={tp}/>))}
                                </div>
                            </Popover.Body>
                        </Popover>
                    }>
                    <Button variant="info" className="shadow-none" id='effect_conditions_btn'>Conditions</Button>
                </OverlayTrigger>

                <Button variant="warning" className="shadow-none"
                        id='add_effect_btn'
                        disabled={!effects.newEffect.type}
                        onClick={() => effects.addEffect()}>
                    Add
                </Button>
            </InputGroup>

            <div className="effects_list">
                {effects.effectsList.filter(e => e.manual).map((effect, index) => {
                    return <EffectLabel key={index} effect={effect} removeEffect={effects.removeEffect.bind(effects)}/>
                })}
            </div>
        </div>
    )
}

export default observer(HeroEffects);