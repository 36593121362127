import HEROES from "../../../data/HEROES";
import HeroFaceComponent from "./HeroFaceComponent";
import CONSTANTS from "../../../data/CONSTANTS";
import {deepClone, isPlayer} from "../../../utilities";

const HeroesList = ({race, pickHero}) => {
	const heroesList = [];
	let heroes

	if (isPlayer(race)) {
		heroes = deepClone(HEROES.CLASSES.PLAYERS)
	} else {
		heroes = {MONSTER: deepClone(HEROES.CLASSES.MONSTER)}
	}

	for (const [, cVal] of Object.entries(heroes)) {
		for (const [sKey, sVal] of Object.entries(cVal.SPECIALIZATIONS)) {
			heroesList.push({
				heroClass: cVal.NAME,
				specialisation: sKey,
				gender: CONSTANTS.HERO.GENDER.MALE,
				position: sVal.GENDERS.MALE.POSITION,
				baseSkills: cVal.SKILLS,
				specializationSkills: sVal.SKILLS,
				advancedSkills: cVal.ADVANCED_SKILLS,
				emptySkillSlots: cVal.EMPTY_SKILL_SLOTS,
				otherSpecializations: cVal.SPECIALIZATIONS
			}, {
				heroClass: cVal.NAME,
				specialisation: sKey,
				gender: CONSTANTS.HERO.GENDER.FEMALE,
				position: sVal.GENDERS.FEMALE.POSITION,
				baseSkills: cVal.SKILLS,
				specializationSkills: sVal.SKILLS,
				advancedSkills: cVal.ADVANCED_SKILLS,
				emptySkillSlots: cVal.EMPTY_SKILL_SLOTS,
				otherSpecializations: cVal.SPECIALIZATIONS
			})
		}
	}

	return (
			<div className="row hero_faces">
				{heroesList.map((hero, index) => {
							hero.race = race
							const heroFaceProps = {hero, key: index}
							return (
									<HeroFaceComponent {...heroFaceProps}>
										<div className="hero_modal" onClick={() => pickHero(hero)}/>
									</HeroFaceComponent>
							)
						}
				)}
			</div>
	)
}

export default HeroesList;