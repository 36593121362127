import BattleSide from "../BattleField/BattleSide";
import CONSTANTS from "../../data/CONSTANTS";
import BattlefieldSetup from "../BattleField/BattlefieldSetup";

const ArmiesInputs = () => (
		<div className="box armies_box armies_inputs">
			<BattleSide side={CONSTANTS.ARMY.SIDE.ATTACKER} type="input"/>
			<BattlefieldSetup/>
			<BattleSide side={CONSTANTS.ARMY.SIDE.DEFENDER} type="input"/>
		</div>
)

export default ArmiesInputs;