import LIMITS from "../../../data/LIMITS";
import {nonFalsyVal} from "../../../utilities";
import CONSTANTS from "../../../data/CONSTANTS";

const BonusProp = ({bonus, name, max}) => {
	bonus = +bonus
	const excluded = [
		CONSTANTS.EFFECTS.EXTRA_HEALS,
		CONSTANTS.EFFECTS.IMPROVED_HEALING,
		CONSTANTS.EFFECTS.IMPROVED_MAGES,
		'TOWER_ATTACK'
	]
	const notUsePercent = ['DEFENCE', CONSTANTS.EFFECTS.EXTRA_HEALS].includes(name)
	const displayValue = `${bonus > 0 ? '+' : ''}${bonus}${notUsePercent ? '' : '%'}`
	let isMin = false,
		isMax = false;
	if (!excluded.includes(name)) {
		isMin = LIMITS[name].min && bonus <= LIMITS[name].min
		isMax = (LIMITS[name].max && bonus >= LIMITS[name].max) || (nonFalsyVal(max) && bonus >= max)
	}

	const color = bonus > 0 ? 'positive' : 'negative'
	const limit = isMin || isMax
	return !!bonus ? <span className={`bonus ${color} ${limit && 'limit'}`}>{displayValue}</span> : null
}

export default BonusProp;