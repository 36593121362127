import CONSTANTS from "./data/CONSTANTS";
import SkillAssets from "./data/skill_assets.json";
import {NewEffectProps} from "./data/NewEffectProps";


export const capitalize = str => str[0].toUpperCase() + str.slice(1);
export const unSnake = str => str.split('_').map(w => capitalize(w)).join(' ')

export const unCamel = str => capitalize(str.replace(/([A-Z])/g, ` $1`))

export const isPlayer = str => ![CONSTANTS.ARMY.RACE.CLAN_CASTLE, CONSTANTS.ARMY.RACE.MONSTER].includes(str)
export const isMonster = str => [CONSTANTS.ARMY.RACE.CLAN_CASTLE, CONSTANTS.ARMY.RACE.MONSTER].includes(str)

export const isOppositeFaction = (first, second) => ![first, second].includes(CONSTANTS.ARMY.FRACTION.NEUTRAL) && first !== second

export const matchValues = (o, l) => o.any || Object.keys(l).some(k => o[k] && l[k])

export const allPropsValue = (Ob, val) => Object.keys(Ob).forEach(key => Ob[key] = val)

export const deepClone = Ob => JSON.parse(JSON.stringify(Ob))

export const newArmyUnits = (origin) => {
	const units = {}
	Object.values(CONSTANTS.ARMY.UNITS).forEach(unit => {
		units[unit] = deepClone(origin[unit])
	})
	return units
}

export const getRandomInt = (min, max) => {
	return min + Math.floor(Math.random() * (max - min + 1))
}

export const sum = arr => arr.reduce((a, b) => a + b)

export const sumArrays = (arrays) => {
	const n = arrays.reduce((max, xs) => Math.max(max, xs.length), 0);
	const result = Array.from({length: n});
	return result.map((_, i) => arrays.map(xs => xs[i] || 0).reduce((sum, x) => sum + x, 0));
}

export const spreadKills = (a, x) => {
	const t = sum(a)
	if (t === 0) return new Array(a.length).fill(0)
	const p = a.map((n, index) => {
		const v = n * x / t;
		return {
			n: Math.floor(v),
			r: v % 1,
			i: index
		}
	})
	p.sort((a, b) => b.r - a.r)
	const tx = sum(p.map(k => k.n))
	const l = x - tx
	for (let i = 0; i < l; i++) {
		p[i].n += 1
	}
	p.sort((a, b) => a.i - b.i)
	return p.map(k => k.n)
}

export const spreadHeal = (ao, h) => {
	const a = [...ao]
	let healAmount = h
	const needHeal = sum(a)

	const reduceUnits = (count) => {
		for (let i = 0; i < a.length; i++) {
			if (a[i] !== 0) {
				if (healAmount <= 0) break
				healAmount -= count
				a[i] -= count
			}
		}
	}

	if (needHeal > healAmount) {
		while (healAmount > 0) {
			const deadUnits = a.filter(x => x !== 0)
			const unitTypeCount = deadUnits.length
			const minUnits = Math.min(...deadUnits)

			if (unitTypeCount * minUnits > healAmount) {
				const fullMax = Math.floor(healAmount / unitTypeCount)
				fullMax > 0 && reduceUnits(fullMax)
				reduceUnits(1)
			} else {
				reduceUnits(minUnits)
			}
		}
		return sumArrays([ao, a.map(x => -x)])
	} else {
		return a
	}
}

export const nonFalsyVal = x => x ? !!x : ['string', 'number'].includes(typeof x) && !isNaN(x)

export const fitMinMax = (val, min = Number.NEGATIVE_INFINITY, max = Number.POSITIVE_INFINITY) => Math.min(max, Math.max(min, val))

export const isPredator = (name) => [
	CONSTANTS.ARMY.UNITS.WARRIOR,
	CONSTANTS.ARMY.UNITS.RIDER,
	CONSTANTS.ARMY.UNITS.FLYING,
	CONSTANTS.ARMY.UNITS.RANGED
].includes(name)

export const getUnitAttack = (unit, atk, round) => {
	let bonus = 100 + unit.bonus_atk
	if (unit.name === CONSTANTS.ARMY.UNITS.MAGE) {
		bonus += unit.extra.inspiration[round - 1]
	}
	return Math.ceil(unit.results.current.initial) * atk * bonus / 100
}

export const getDmgPerUnit = unit => unit.base_hp * (100 + unit.bonus_hp) / (100 - unit.bonus_def)

export const isTower = type => [CONSTANTS.DEF_BUILDINGS.TOWER, CONSTANTS.DEF_BUILDINGS.MAGIC_TOWER].includes(type)

const listValToInt = con => {
	let c = 0;
	Object.entries(con).reverse().forEach(([, value], index) => {
		c += 2 ** index * value
	});

	return c
}

export const getEffectKeys = type => {
	const keys = Object.keys(NewEffectProps).filter(k => !['id', 'type', 'amount', 'active'].includes(k))
	const checkboxes = ['parties', 'units', 'race', 'terrain']
	switch (type) {
		case 'checkbox':
			return checkboxes
		case 'radio':
			return keys.filter(k => !checkboxes.includes(k))
		default:
			return keys
	}
}

const effectDefaults = effect => {
	if (!effectDefaults.defaults) {
		const checkboxKeys = getEffectKeys('checkbox')

		effectDefaults.defaults = getEffectKeys().map(x => {
			const m = Object.keys(effect[x]).length
			return checkboxKeys.includes(x) ? 2 ** m - 1 : 2 ** (m - 1)
		})
	}

	return effectDefaults.defaults
}

export const encodeEffect = effect => {
	const defaults = effectDefaults(effect)
	const r = getEffectKeys().map(x => listValToInt(effect[x]));

	let iter = r.length - 1
	while (iter >= 0 && defaults[iter] === r[iter]) iter -= 1

	const p = r.slice(0, iter + 1)
	const main = [effect.type, effect.amount]

	if (p.length > 0) main.push(p.join('.'))

	return main.join('.')
}

export const decodeEffect = effectStr => {
	const a = effectStr.split('.'),
		returnEffect = deepClone(NewEffectProps);

	returnEffect.type = a[0]
	returnEffect.amount = parseInt(a[1]);

	getEffectKeys().every((prop, i) => {
		if (a.length > i + 2) {
			const propVal = parseInt(a[i + 2]),
				retProp = returnEffect[prop];
			Object.keys(retProp).reverse().forEach((k, i2) => {
				retProp[k] = (propVal | (2 ** i2)) === propVal
			})
			return true
		} else {
			return false
		}
	})

	return returnEffect
}

export const compareEffects = (e1, e2) => {
	return encodeEffect(e1) === encodeEffect(e2)
}

export const getSkillSprite = name => {
	return SkillAssets.skills.find(sk => sk.skillName === name)
}

export const getSprite = sprite => {
	if (!sprite) return {}
	const pos = sprite.split(":")
	return {
		backgroundPosition: `-${pos[0]}px -${pos[1]}px`,
		minWidth: `${pos[2]}px`,
		width: `${pos[2]}px`,
		minHeight: `${pos[3]}px`,
		height: `${pos[3]}px`
	}
}

export const skillInfoStat = (description, stat) => {
	for (let i = 0; i < stat.length; i++) {
		const re = new RegExp(`\\{${i}\\}`)
		description = description.replace(re, stat[i])
	}
	return description
}

// todo: properly name this function
/**
 * Add the amount value to dynamically defined effects
 * @param EFFECTS - the list of encoded effects
 * @param stat - the stat list for all skill levels
 * @returns {*}
 */
export const filterEffect = (EFFECTS, stat) => {
	let effectsList = EFFECTS
	for (let i = 0; i < stat.length; i++) {
		const re = new RegExp(`\\{${i}\\}`)
		effectsList = effectsList.map(e => e.replace(re, stat[i]))
	}
	return effectsList
}

// todo: properly name this function
export const filterHeroEffects = heroEffects => {
	return heroEffects.filter(x => x.lvl && x.EFFECTS && !x.active).map(x => {
		let stats = x.STATS
		if (!Array.isArray(stats[0])) {
			stats = stats.map(s => [s])
		}

		const s = stats[x.lvl - 1]
		return filterEffect(x.EFFECTS, s)
	}).flat()
}

export const compareObjectValues = (ob, testOb) => Object.keys(testOb).every(k => ob[k] === testOb[k])

export const reduceTowers = (t1, t2, c) => {
	if (c < 1) return [t1, t2]
	if (c >= t1 + t2) return [0, 0]

	let diff = Math.abs(t1 - t2)

	if (diff >= c) {
		return t1 < t2 ? [t1, t2 - c] : [t1 - c, t2]
	}

	c -= diff
	const m = Math.min(t1, t2)
	t1 = m - Math.ceil(c / 2)
	t2 = m - Math.floor(c / 2)

	return [t1, t2]
}

export const fortuneAttack = (atk, fortune) => {
	if (fortune === CONSTANTS.FORTUNE.RANDOM) return getRandomInt(atk.min, atk.max)
	else return atk[fortune]
}

export const stripArmyName = name => name.replace(CONSTANTS.ARMY.SAVED_ARMY_PREFIX, '')

export const makeAncientEffect = str => str.replace(/([a-z]+).(-?\d+)./, (match, g1, g2) => `${g1}.${Math.round(g2 * 1.2)}.`)

export const getPerfectArtifactEffects = (place, lvl) => {
	const mapEffects = {
		back: {
			description: `Espionage radius ^__+${lvl}\nVisionaries radius ^__+${lvl}`,
			effects: null
		},
		belt: {
			description: `Increase bag size by ^__+${lvl * 3}`,
			effects: null
		},
		footwear: {
			description: `Incoming attacks duration ^__+${lvl * 5}%`,
			effects: null
		},
		helmet: {
			description: `Mission count ^__+${[1, 3, 5, 9, 15][lvl - 1]}`,
			effects: [`tower_attack.${[25, 50, 100, 150, 200][lvl - 1]}.4`]
		},
		item: {
			description: '',
			effects: [`exp.${lvl * 50}.4.255.63.511.4.8`]
		},
		necklace: {
			description: `Chance of finding magic item in the ruins ^__+${lvl * 50}%`,
			effects: null
		},
		ring: {
			description: '',
			effects: [`magic_tower_attack.${lvl * 5}.4`]
		},
		shield: {
			description: '',
			effects: [`attack.${lvl * 3}.4`]
		},
		thigh: {
			description: '',
			effects: [`defence.${lvl * 3}.4`]
		},
		weapon: {
			description: '',
			effects: [`attack.-${lvl * 3}.1.2`, `max_defence.${lvl}.4`]
		},
		wear: {
			description: '',
			effects: [`fortifications_effectiveness.${[50, 100, 150, 200, 300][lvl - 1]}.4`, `hp.${lvl * 3}.4`]
		},
		wrist: {
			description: '',
			effects: [`magic_tower_attack.-${lvl * 3}.1`]
		},
	}
	return mapEffects[place]
}

export const descriptionToHtml = desc => {
	const re = /\^__(([+-]?)\d+%?\/?[a-z]{0,})/,
		re2 = /\^___(([+-]?)\d+%?)/,
		matches = desc.match(re),
		negativeMatches = desc.match(re2),
		s1 = desc.split(re),
		s2 = desc.split(re2)

	return matches
		? (<>
			{s1[0]}
			<span className={matches[2] === '-' ? 'negative' : 'positive'}>{matches[1]}</span>
			{s1.length > 1 && s1[3]}
		</>)
		: negativeMatches
			? (<>
				{s2[0]}
				<span className={negativeMatches[2] === '-' ? 'positive' : 'negative'}>{negativeMatches[1]}</span>
				{s2.length > 1 && s2[3]}
			</>)
			: desc
}

export const getAncientDescription = desc => desc.replaceAll(/(\^_+[+-]?)(\d+)(%?)/g,
	(match, g1, g2, g3) => g1 + Math.round(g2 * 1.2) + g3)



