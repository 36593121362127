export const BattlefieldEffects = [
    "defence.25.7.255.32.128.4.16.4.4.1",
    "defence.25.7.255.16.256.4.16.4.4.1",
    "defence.25.7.255.8.64.4.16.4.4.1",
    "defence.25.7.255.6.32.4.16.4.4.1",
    "defence.-25.7.255.6.32.4.1.2.1.4",
    "defence.-25.7.255.8.64.4.1.2.1.4",
    "defence.-25.7.255.16.256.4.1.2.1.4",
    "defence.-25.7.255.32.128.4.1.2.1.4",
    "attack.50.7.64.63.16.4.16.4.4.4",
    "attack.50.7.32.63.8.4.16.4.4.4",
    "attack.50.7.16.63.2.4.16.4.4.4",
    "attack.50.7.8.63.4.4.16.4.4.4",
    "attack.-50.7.64.63.2.4.16.4.4.4",
    "attack.-50.7.32.63.4.4.16.4.4.4",
    "attack.-50.7.16.63.16.4.16.4.4.4",
    "attack.-50.7.8.63.8.4.16.4.4.4",
    "attack.-50.7.2.63.1.4.16.4.4.4",
    "attack.-25.7.255.63.511.4.2.2.1.4",
    "attack.-25.7.255.63.511.4.1.2.1.4",
    "defence.0.7.255.63.511.4.16.4.4.4"     // this will reset all conditions to initial
]
