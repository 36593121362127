const CONSTANTS = {
	ARMY: {
		SIDE: {
			ATTACKER: 'attacker',
			DEFENDER: 'defender'
		},
		UNITS: {
			CARRIER: "carrier",
			WARRIOR: "warrior",
			RIDER: "rider",
			FLYING: "flying",
			RANGED: "ranged",
			HEALER: "healer",
			MERCENARY: "mercenary",
			MAGE: "mage",
		},
		FRACTION: {
			LIGHT: 'light',
			DARK: 'dark',
			NEUTRAL: 'neutral'
		},
		RACE: {
			HUMAN: 'human',
			ELF: 'elf',
			DROW: 'drow',
			DEMON: 'demon',
			UNDEAD: 'undead',
			MONSTER: 'monster',
			CLAN_CASTLE: 'clan_castle',
		},
		SAVED_ARMY_PREFIX: 'saved_army_'
	},
	HERO: {
		GENDER: {
			MALE: 'male',
			FEMALE: 'female'
		}
	},
	TERRAIN: {
		MAGIC_FORREST: 'magic_forrest',
		HOLY_LAND: 'holy_land',
		DEAD_LAND: 'dead_land',
		CURSED_FORREST: 'cursed_forrest',
		DESERT: 'desert',
		STEPPE: 'steppe',
		FORREST: 'forrest',
		MOUNTAIN: 'mountain',
		UNDERGROUND: 'underground'
	},
	DEF_BUILDINGS: {
		FORTIFICATION: 'fortification',
		TOWER: 'tower',
		MAGIC_TOWER: 'magic_tower',
		GATES: 'gates'
	},
	EFFECTS: {
		ATTACK: 'attack',
		ATTACK_AMPLIFICATION: 'attack_amplification',
		EXTRA_ATTACK: 'extra_attack',
		DEFENCE: 'defence',
		MAX_DEFENCE: 'max_defence',
		HP: 'hp',
		PREDATOR_DAMAGE: 'predator_damage',
		TOWER_ATTACK: 'tower_attack',
		MAGIC_TOWER_ATTACK: 'magic_tower_attack',
		CATAPULT: 'catapult',
		FORTIFICATION_KILLS: 'fortification_kills',
		FORTIFICATIONS_EFFECTIVENESS: 'fortifications_effectiveness',
		IMPROVED_HEALING: 'improved_healing',
		MERCY_KILLING: 'mercy_killing',
		IGNORE_MERCY_KILLING: 'ignore_mercy_killing',
		EXTRA_HEALS: 'extra_heals',
		IMPROVED_MAGES: 'improved_mages',
		MAGES_DMG_REDUCTION: 'mages_dmg_reduction',
		MAGES_INSPIRATION: 'mages_inspiration',
		EXP: 'exp',
		FACTION_RATING: 'faction_rating',
		TERROR: 'terror',
		FEARLESSNESS: 'fearlessness',
		DRAGON_MOTHER: 'dragon_mother',
		NECROMANCY: 'necromancy',
		GOOD_LUCK: 'good_luck',
		BAD_LUCK: 'bad_luck',
		CHASING_OF_ASSASSIN: 'chasing_of_assassin',
		STONE_ARMOR: 'stone_armor',
		MAMMOTH_HEALTH: 'mammoth_health',
		SHARP_SPADE: 'sharp_spade',
		GATE_ENDURANCE: 'gate_endurance'
	},
	FORTUNE: {
		MIN: 'min',
		MAX: 'max',
		RANDOM: 'random',
		CUSTOM: 'custom'
	}
}

export default CONSTANTS;