const UNIT_SPRITES = {
	player: {
		carrier: ['-390px -283px', '-460px -283px', '-530px -283px', '-530px -283px'],
		warrior: ['-180px -283px', '-250px -283px', '-320px -283px', '-320px -283px'],
		rider: ['-306px -115px', '-376px -199px', '-376px -115px', '-376px -115px'],
		flying: ['-446px -199px', '-446px -115px', '-516px -199px', '-516px -199px'],
		ranged: ['-600px -283px', '-96px -98px', '-76px -14px', '-76px -14px'],
		healer: ['-146px -14px', '-166px -182px', '-166px -98px', '-166px -98px'],
		mercenary: ['-236px -199px', '-236px -115px', '-306px -199px', '-306px -199px'],
		mage: ['-516px -115px', '-586px -199px', '-586px -115px', '-586px -115px'],
		tower: '-216px -14px',
		magic_tower: '-286px -31px'
	},
	monster: {
		carrier: ['-224px -185px', '-294px -185px', '-364px -185px', '-364px -185px'],
		warrior: ['-84px -185px', '0px 0px', '-154px -185px', '-154px -185px'],
		rider: ['-210px -101px', '-210px -17px', '-280px -101px', '-280px -101px'],
		flying: ['-280px -17px', '-350px -101px', '-350px -17px', '-350px -17px'],
		ranged: ['-434px -185px', '-504px -185px', '-574px -185px', '-574px -185px'],
		healer: ['-644px -185px', '-714px -185px', '-70px -84px', '-70px -84px'],
		mercenary: ['-70px 0px', '-140px -101px', '-140px -17px', '-140px -17px'],
		mage: ['-420px -101px', '-420px -17px', '-490px -101px', '-490px -101px'],
		tower: '-560px -17px',
		magic_tower: '-630px -101px',
		gates: '-630px -17px'
	},
	get elf() {
		return this.player
	},
	get human() {
		return this.player
	},
	get demon() {
		return this.player
	},
	get drow() {
		return this.player
	},
	get undead() {
		return this.player
	},
	get neutral() {
		return this.player
	},
	get clan_castle() {
		return this.monster
	},
}

export default UNIT_SPRITES;